import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { nanoid } from "nanoid";
import {
  extractMatricDigits,
  extractAllTeachers,
  calcDisplayNo,
} from "../../AppManager";
import MemberCard from "../../components/MemberCard";
import Paginate from "../../components/Paginate";
import EmptySearch from "../../components/EmptySearch";

function AdminAllTeachers() {
  const { search, record, setShowSearch } = useOutletContext();
  const [teachers, setTeachers] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const width = 160;
    const height = 220;
    const pagePadMarginWidth = 20;
    const pagePadMarginHeight = 20;
    setItemsPerPage(
      calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight)
    );
    window.addEventListener("resize", () => {
      setItemsPerPage(
        calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight)
      );
    });

    return () => {
      window.removeEventListener("resize", () => {
        setItemsPerPage(
          calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight)
        );
      });
    };
  }, []);

  useEffect(() => {
    setTeachers(extractAllTeachers(record));
  }, [record]);

  useEffect(() => {
    setShowSearch(true);
  });

  useEffect(() => {
    document.title = "All Teachers";
  }, []);

  const teacherElements = teachers
    .filter(
      (dat) =>
        dat.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        dat.matric.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        dat.class.toLowerCase().indexOf(search.toLowerCase()) > -1
    )
    .map((dat) => (
      <MemberCard
        key={nanoid()}
        name={dat.name}
        image={dat.image}
        matric={dat.matric}
        to={extractMatricDigits(dat.matric)}
        imgfolder="teachers"
      />
    ));

  return (
    <div className="overview-students">
      <h1 className="heading1" style={{ width: "100vw" }}>
        All Teachers
      </h1>
      {teacherElements.length === 0 ? (
        <EmptySearch message={"No Teacher Found"} />
      ) : (
        <Paginate itemsPerPage={itemsPerPage} items={teacherElements} />
      )}
    </div>
  );
}

export default AdminAllTeachers;
