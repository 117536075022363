import React from "react";
import { NavLink, Outlet, useOutletContext } from "react-router-dom";
import { FaBars } from "react-icons/fa6";

function Attendance() {
  const { classRecord, setClassRecord, user } = useOutletContext();

  return (
    <div className="attendanceform">
      <button
        className="text-btn attendance-nav-btn"
        style={{ width: "auto", padding: "10px" }}
      >
        <FaBars />
      </button>
      <ul id="optionswrapper">
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : null)}
            to="signin"
          >
            Sign-In
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : null)}
            to="statistics"
          >
            Statistics
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : null)}
            to="special"
          >
            Special
          </NavLink>
        </li>
      </ul>
      <Outlet context={{ classRecord, setClassRecord, user }} />
    </div>
  );
}

export default Attendance;
