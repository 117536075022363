import axiosMain from "axios";

const origin = window.location.origin;
export const baseURL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:80/www/ruc-training-unit/public/"
    : origin + "/";
export const downloadBaseURL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "http://localhost:80/www/ruc-training-unit/files/"
    : origin + "/files/";

export const axios = axiosMain.create({
  baseURL,
});

axios.interceptors.request.use(
  (config) => {
    const toke = getTokenDetails();
    const { matric, position, centre, Class, token } = toke;

    if (matric && position && centre && Class && token) {
      const auth_data = encodeString(
        JSON.stringify({
          auth_matric: matric,
          auth_position: position,
          auth_centre: centre,
          auth_Class: Class,
          auth_token: token,
        })
      );

      if (
        config.method === "post" ||
        config.method === "put" ||
        config.method === "patch"
      ) {
        if (!(config.data instanceof FormData)) {
          config.data = new FormData();
        }
        config.data.append("auth_data", auth_data);
      }
    }

    config.mode = config.mode || "cors";

    if (!(config.data instanceof FormData)) {
      config.headers = {
        "Content-Type": "application/json",
        ...(config.headers || {}),
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function setCookie(cname, cvalue, exdays = 1 / 3) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + "; path=/";
}

export function removeCookie(cname) {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getTokenDetails() {
  try {
    const toke = getCookie("sm327 ur93j");
    if (!toke) {
      removeCookie("sm327 ur93j");
      return {
        matric: false,
        position: false,
        centre: false,
        Class: false,
        token: false,
      };
    }
    const { matric, position, centre, Class, token } = JSON.parse(
      decodeString(toke)
    );
    return { matric, position, centre, Class, token };
  } catch (error) {
    removeCookie("sm327 ur93j");
    return {
      matric: false,
      position: false,
      centre: false,
      Class: false,
      token: false,
    };
  }
}

export async function userVerification(password) {
  try {
    const token = getCookie("sm327 ur93j");
    if (!token) {
      return "Failed";
    }
    const { matric, position } = JSON.parse(decodeString(token));
    if (!matric || !position) {
      return "Failed";
    }
    const data = {
      matric,
      password,
      position,
      user_verification: "ldkendwejnkf",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata, {
      mode: "cors",
    });
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function getCurrUser() {
  try {
    const toke = getCookie("sm327 ur93j");

    if (!toke) {
      removeCookie("sm327 ur93j");
      localStorage.setItem("soifdjksnfjkew", "true");
      setTimeout(() => {
        localStorage.setItem("soifdjksnfjkew", "false");
      }, 2000);
      return { class: "", position: "none", matric: "", centre: "" };
    }

    const { matric, position, Class, centre, token } = JSON.parse(
      decodeString(toke)
    );

    if (!Class || !matric || !position || !token || !centre) {
      removeCookie("sm327 ur93j");
      localStorage.setItem("soifdjksnfjkew", "true");
      setTimeout(() => {
        localStorage.setItem("soifdjksnfjkew", "false");
      }, 2000);
      return { class: "", position: "none", matric: "", centre: "" };
    }

    const data = {
      class: Class.toLowerCase(),
      matric,
      position,
      token,
      get_curr_user: "mwekjnekj",
    };

    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata, {
      mode: "cors",
    });

    if (typeof send.data === "object" && send.data.status === "Confirmed") {
      setCookie(
        "sm327 ur93j",
        encodeString(
          JSON.stringify({
            matric,
            position,
            centre,
            Class,
            token: send.data.token,
            auth_matric: matric,
            auth_position: position,
            auth_centre: centre,
            auth_Class: Class,
            auth_token: send.data.token,
          })
        )
      );
      return { class: Class.toLowerCase(), position, matric, centre };
    } else {
      return { class: "", position: "none", matric: "", centre: "" };
    }
  } catch (error) {
    return { class: "", position: "none", matric: "", centre: "" };
    // return error;
  }
}

export const clearUser = () => {
  try {
    removeCookie("sm327 ur93j");
    localStorage.setItem("soifdjksnfjkew", "true");
    setTimeout(() => {
      localStorage.setItem("soifdjksnfjkew", "false");
    }, 2000);
    return true;
  } catch (error) {
    return false;
  }
};

export const authRequired = async () => {
  const user = await getCurrUser();
  if (user.position !== "" && user.position !== "none") {
    return true;
  }
  return false;
};

export const teacherAuthRequired = async () => {
  const user = await getCurrUser();
  if (user.position === "teacher" || user.position === "assistant teacher") {
    return true;
  }
  return false;
};

export const adminAuthRequired = async () => {
  const user = await getCurrUser();
  if (user.position === "admin") {
    return true;
  }
  return false;
};

export async function adminLogin(password) {
  try {
    const data = { password, admin_login: "wkmlfknjw" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata, {
      mode: "cors",
    });
    const res = send.data.split(", ");
    if (res[0] === "Confirmed") {
      setCookie(
        "sm327 ur93j",
        encodeString(
          JSON.stringify({
            matric: "RUN/AAA/00/00000",
            position: "admin",
            centre: "admin",
            Class: "admin",
            token: res[1],
            auth_matric: "RUN/AAA/00/00000",
            auth_position: "admin",
            auth_centre: "admin",
            auth_Class: "admin",
            auth_token: res[1],
          })
        )
      );
    }
    return res[0];
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function login(matric, position, password, remember = false) {
  try {
    const data = { matric, position, password, login: "lmhiturnd" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata, {
      mode: "cors",
    });
    const res = send.data.split(", ");

    if (res[0] === "Confirmed") {
      if (remember) {
        localStorage.setItem(
          position === "student" ? "mkoe2ojoi4o" : `jxnNS4uenk23k`,
          encodeString(res[1])
        );
        localStorage.setItem(
          position === "student" ? "ndi2n4r5i983nf" : `jnxkj5rn32kn39nd`,
          encodeString(password)
        );
      }
      setCookie(
        "sm327 ur93j",
        encodeString(
          JSON.stringify({
            matric: res[1],
            position: res[2],
            centre: res[3],
            Class: res[4],
            token: res[5],
            auth_matric: res[1],
            auth_position: res[2],
            auth_centre: res[3],
            auth_Class: res[4],
            auth_token: res[5],
          })
        )
      );
      return "Success";
    } else {
      return res[0];
    }
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function forgottenPassword(matric, position) {
  try {
    const data = { matric, position, forgot_password: "ekmlwemelfmr" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata, {
      mode: "cors",
    });
    const res = send.data.split(", ");
    // if(res[0] === "Success"){
    return res;
    // }else{
    //     return res[0];
    // }
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function passwordReset(matric, token, password, confirmpassword) {
  try {
    const data = {
      matric,
      token,
      password,
      confirmpassword,
      password_reset: "ecrtjlerlte",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata, {
      mode: "cors",
    });
    return send.data.split(", ");
  } catch (error) {
    return ["Failed"];
    // return error;
  }
}

export async function changePassword(formData) {
  try {
    const { matric, position, oldpassword, newpassword, confirmpassword } =
      formData;
    const data = {
      matric,
      position,
      oldpassword,
      newpassword,
      confirmpassword,
      change_password: "nkmyniumjmk",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/verification.php";
    const send = await axios.post(url, formdata, {
      mode: "cors",
    });
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export function encodeString(string) {
  if (!string) return string;
  const key = process.env.REACT_APP_SUPER_KEY;
  let encoded = "";
  for (let i = 0; i < string.length; i++) {
    let charCode = string.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    encoded += String.fromCharCode(charCode);
  }
  return btoa(encoded);
}

export function decodeString(encodedString) {
  if (!encodedString) return encodedString;
  const key = process.env.REACT_APP_SUPER_KEY;
  let decodedBase64 = atob(encodedString);
  let decoded = "";
  for (let i = 0; i < decodedBase64.length; i++) {
    let charCode = decodedBase64.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    decoded += String.fromCharCode(charCode);
  }
  return decoded;
}
