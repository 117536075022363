import React, { useEffect, useState } from "react";
import { FaSpinner, FaUserGroup } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { useOutletContext } from "react-router-dom";
import { setMaximumStudents } from "../../AppManager";

function MaxStudents() {
  const { setMaxStudents, setPreventRefetch } = useOutletContext();
  const [maximum, setMaximum] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: "", type: "", message: "" });

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "maximum") {
      setMaximum(value);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await setMaximumStudents(maximum);
      if (res === "Updated") {
        setMaxStudents(maximum);
        setStatus({
          status: "success",
          type: "success",
          message: "Update Successful",
        });
        setLoading(false);
        setMaximum("");
      } else {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setLoading(false);
      }
    } catch (error) {
      setStatus({ status: "error", type: "failed", message: "Update Failed" });
      setLoading(false);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  useEffect(() => {
    document.title = "Max Students";
  }, []);

  useEffect(() => {
    setPreventRefetch(true);
  });

  return (
    <div className="formwrapper" style={{ margin: "50px 0" }}>
      <div className="wrapper" style={{ height: "330px" }}>
        <div className="form-box login">
          <h2>Max Students</h2>
          <form onSubmit={handleSubmit}>
            {status.message !== "" && (
              <p className="status-wrapper" id="status-wrapper">
                <span className={`status-message ${status.type}`}>
                  {status.message}
                </span>
                <LiaTimesCircle
                  className="status-close"
                  onClick={() => {
                    setStatus({ status: "", type: "", message: "" });
                  }}
                />
              </p>
            )}
            <div className="input-box">
              <span className="icon">
                <FaUserGroup />
              </span>
              <input
                readOnly={loading}
                type="number"
                onChange={handleChange}
                value={maximum}
                autoComplete="off"
                required
                min={1}
                name="maximum"
                placeholder=" "
              />
              <label>New Maximum</label>
            </div>
            <button type="submit" disabled={loading} className="btn">
              {loading ? <FaSpinner className="spinner" /> : "Update"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MaxStudents;
