import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { nanoid } from "nanoid";
import {
  calcDisplayNo,
  extractAllStudents,
  extractMatricDigits,
} from "../../AppManager";
import MemberCard from "../../components/MemberCard";
import Paginate from "../../components/Paginate";
import EmptySearch from "../../components/EmptySearch";

export function StudentsLoader() {
  return (
    <>
      <div
        className="loader-item"
        style={{
          width: "150px",
          height: "200px",
          background: "linear-gradient(to top right, #F4F6FF 0%, #FFF 100%)",
          backgroundSize: "200% 100%",
          boxShadow: "0 5px 8px rgba(0, 0, 0, 0.4)",
          borderRadius: "5px",
          margin: "10px 5px",
        }}
      ></div>
      <div
        className="loader-item"
        style={{
          width: "150px",
          height: "200px",
          background: "linear-gradient(to top right, #F4F6FF 0%, #FFF 100%)",
          backgroundSize: "200% 100%",
          boxShadow: "0 5px 8px rgba(0, 0, 0, 0.4)",
          borderRadius: "5px",
          margin: "10px 5px",
        }}
      ></div>
      <div
        className="loader-item"
        style={{
          width: "150px",
          height: "200px",
          background: "linear-gradient(to top right, #F4F6FF 0%, #FFF 100%)",
          backgroundSize: "200% 100%",
          boxShadow: "0 5px 8px rgba(0, 0, 0, 0.4)",
          borderRadius: "5px",
          margin: "10px 5px",
        }}
      ></div>
    </>
  );
}

function AllStudents() {
  const { search, record, setShowSearch } = useOutletContext();
  const [students, setStudents] = useState([]);
  const [studentsFetch, setStudentsFetch] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const width = 160;
    const height = 220;
    const pagePadMarginWidth = 20;
    const pagePadMarginHeight = 20;
    setItemsPerPage(
      calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight)
    );
    window.addEventListener("resize", () => {
      setItemsPerPage(
        calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight)
      );
    });

    return () => {
      window.removeEventListener("resize", () => {
        setItemsPerPage(
          calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight)
        );
      });
    };
  }, []);

  useEffect(() => {
    extractAllStudents(record).then((data) => {
      setStudents(data);
      setStudentsFetch(true);
    });
  }, [record]);

  useEffect(() => {
    setShowSearch(true);
  });

  useEffect(() => {
    document.title = "All Students";
  }, []);

  const studentsElement = students
    .filter(
      (dat) =>
        dat.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        dat.matric.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        dat.class.toLowerCase().indexOf(search.toLowerCase()) > -1
    )
    .map((dat) => (
      <MemberCard
        key={nanoid()}
        name={dat.name}
        image={dat.image}
        matric={dat.matric}
        to={extractMatricDigits(dat.matric)}
        imgfolder="students"
      />
    ));

  return (
    <div className="overview-students">
      <h1 className="heading1" style={{ width: "100vw" }}>
        All Students
      </h1>
      {studentsFetch ? (
        studentsElement.length === 0 ? (
          <EmptySearch message={"No Student Found"} />
        ) : (
          <Paginate itemsPerPage={itemsPerPage} items={studentsElement} />
        )
      ) : (
        <StudentsLoader />
      )}
    </div>
  );
}

export default AllStudents;
