import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import {
  calcDisplayNo,
  extractClassStudents,
  extractMatricDigits,
} from "../../AppManager";
import MemberCard from "../../components/MemberCard";
import Paginate from "../../components/Paginate";
import EmptySearch from "../../components/EmptySearch";
import { StudentsLoader } from "../overview/AllStudents";

function AdminStudents() {
  const { Class } = useParams();
  const { search, setShowSearch } = useOutletContext();
  const [students, setStudents] = useState([]);
  const [studentsFetch, setStudentsFetch] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    document.title = "Students";
  }, []);

  useEffect(() => {
    const width = 160;
    const height = 220;
    const pagePadMarginWidth = 20;
    const pagePadMarginHeight = 20;
    setItemsPerPage(
      calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight)
    );
    window.addEventListener("resize", () => {
      setItemsPerPage(
        calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight)
      );
    });

    return () => {
      window.removeEventListener("resize", () => {
        setItemsPerPage(
          calcDisplayNo(width, height, pagePadMarginWidth, pagePadMarginHeight)
        );
      });
    };
  }, []);

  useEffect(() => {
    extractClassStudents(Class)
      .then((data) => {
        setStudents(data);
        setStudentsFetch(true);
      })
      .catch((err) => err);
  }, [Class]);

  useEffect(() => {
    setShowSearch(true);
  });

  const studentsElement = students
    .filter(
      (dat) =>
        dat.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        dat.matric.toLowerCase().indexOf(search.toLowerCase()) > -1
    )
    .map((dat) => (
      <MemberCard
        key={nanoid()}
        name={dat.name}
        image={dat.image}
        matric={dat.matric}
        to={extractMatricDigits(dat.matric)}
        imgfolder="students"
      />
    ));

  return (
    <div className="overview-students">
      <h1 className="heading1" style={{ width: "100vw" }}>
        {Class} Students
      </h1>
      {studentsFetch ? (
        studentsElement.length === 0 ? (
          <EmptySearch message={"No Student Found"} />
        ) : (
          <Paginate itemsPerPage={itemsPerPage} items={studentsElement} />
        )
      ) : (
        <StudentsLoader />
      )}
    </div>
  );
}

export default AdminStudents;
