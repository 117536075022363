import React, { useEffect, useState } from "react";
import { FaKey, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  deleteStudent,
  getStudent,
  showSwal,
  stripSpace,
} from "../../AppManager";
import { nanoid } from "nanoid";

function DeleteStudent() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const returnPath = pathname.slice(0, pathname.indexOf(`/${id}`));
  const { record, setRecord, classRecord, setClassRecord } = useOutletContext();
  const navigate = useNavigate();
  const [crkey, setCrkey] = useState("");
  const [keyword, setKeyword] = useState("loading...");
  const [status, setStatus] = useState({ status: "", type: "", message: "" });
  const [student, setStudent] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStudent(id, record)
      .then((data) => {
        setStudent(data);
        const trimname = stripSpace(data.name);
        setKeyword(
          trimname.length >= 10
            ? trimname.slice(0, 10)
            : trimname.padEnd("10", nanoid(10 - trimname.length))
        );
      })
      .catch(() => {
        if (status.message !== "Deleted Successfully. Redirecting...") {
          showSwal("error", "Student not Found");
          navigate(returnPath, { replace: true });
        }
      });
  }, [id, record, navigate, returnPath, status]);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "crkey") {
      setCrkey(value);
      if (value !== "" && value !== keyword) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (crkey === keyword) {
        const res = await deleteStudent({
          matric: student["matric"],
          image: student.image,
          Class: student.class,
        });
        if (res === "Deleted") {
          const newRecord = {};
          for (const key in classRecord) {
            if (Object.hasOwnProperty.call(classRecord, key)) {
              const rec = classRecord[key];
              if (key !== id) {
                newRecord[key] = rec;
              }
            }
          }
          setClassRecord(newRecord);
          setRecord((oldRecord) => {
            let updatedClasses = [
              ...oldRecord[student.centre][student.trainingClass].classes,
            ];
            for (let k = 0; k < updatedClasses.length; k++) {
              const Class = updatedClasses[k];
              if (Class.name === student.class) {
                let newNstudents = parseInt(Class.students) - 1;
                updatedClasses[k] = {
                  ...Class,
                  students: newNstudents.toString(),
                };
              }
            }
            return {
              ...oldRecord,
              [student.centre]: {
                ...oldRecord[student.centre],
                [student.trainingClass]: {
                  ...oldRecord[student.centre][student.trainingClass],
                  classes: updatedClasses,
                },
              },
            };
          });
          setStatus({
            status: "success",
            type: "success",
            message: "Deleted Successfully. Redirecting...",
          });
          setCrkey("");
          setTimeout(() => {
            navigate(returnPath, { replace: true });
          }, 2000);
        } else if (res === "Not Found") {
          showSwal("error", "Student not Found");
          navigate(returnPath, { replace: true });
        } else {
          setTimeout(() => {
            setStatus({
              status: "error",
              type: "failed",
              message: "Delete Failed",
            });
            setLoading(false);
          }, 1000);
        }
      } else {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "error",
            message: "Incorrect Key",
          });
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Delete Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  useEffect(() => {
    document.title = "Delete Student";
  }, []);

  return (
    <div className="attendanceform">
      <div className="formwrapper">
        <div
          className="wrapper"
          style={{
            height: status.message !== "" ? "350px" : "330px",
            color: "#002F63",
          }}
        >
          <div className="form-box login">
            <h2>Delete Student</h2>
            <form onSubmit={handleSubmit}>
              {status.message !== "" && (
                <p className="status-wrapper" id="status-wrapper">
                  <span className={`status-message ${status.type}`}>
                    {status.message}
                  </span>
                  <LiaTimesCircle
                    className="status-close"
                    onClick={() => {
                      setStatus({ status: "", type: "", message: "" });
                    }}
                  />
                </p>
              )}
              <p style={{ fontSize: "16px", marginTop: "5px" }}>
                Type <strong>{keyword}</strong> to complete removal process
              </p>
              <div className="input-box">
                <span className="icon">
                  <FaKey />
                </span>
                <input
                  readOnly={loading}
                  type="text"
                  onChange={handleChange}
                  value={crkey}
                  autoComplete="off"
                  maxLength={10}
                  minLength={10}
                  required
                  className="input"
                  id="crkey"
                  name="crkey"
                  placeholder=" "
                />
                <label>Key</label>
              </div>
              <button
                className="btn"
                disabled={disabled || loading || keyword === "loading..."}
              >
                {loading ? <FaSpinner className="spinner" /> : "Remove"}
              </button>
              <p style={{ fontSize: "14px", marginTop: "5px" }}>
                <strong>Note:</strong> This action is final and irreversible.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteStudent;
