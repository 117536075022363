import React, { useEffect, useState } from "react";
import {
  FaChalkboardUser,
  FaChurch,
  FaEnvelope,
  FaEye,
  FaEyeSlash,
  FaHandshake,
  FaIdCard,
  FaLock,
  FaSpinner,
} from "react-icons/fa6";
import { LiaCheckCircle, LiaTimesCircle } from "react-icons/lia";
import {
  capitalizeEachWord,
  cleanMatric,
  cleanName,
  passwordStrength,
  checkExtension,
  getCentresDirect,
  getCentreClassesDirect,
  teacherRegistration,
} from "../AppManager";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";

function TeacherRegistration() {
  const [formData, setFormData] = useState({
    matric: "",
    email: "",
    class: "",
    centre: "",
    image: "",
    password: "",
    confirmpassword: "",
  });
  const [status, setStatus] = useState({ status: "", type: "", message: "" });
  const [centres, setCentres] = useState([]);
  const [classes, setClasses] = useState([]);
  const [showp, setShowp] = useState(false);
  const [mismatch, setMismatch] = useState(false);
  const [checkProgress, setCheckProgress] = useState({
    strength: "0%",
    color: "red",
    upper: false,
    lower: false,
    special: false,
    length: false,
    number: false,
  });
  const [imgErr, setImgErr] = useState({ name: "", reason: "", status: true });
  const [uprogress, setUprogess] = useState(0);
  const [ustate, setUstate] = useState("");
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Teacher Registration";
  }, []);

  function handleChange(e) {
    const { name, type, value, checked, files } = e.target;
    setFormData((oldFormData) => {
      let newFormData = {
        ...oldFormData,
        [name]:
          type === "checkbox"
            ? checked
            : type === "file"
            ? files[0]
            : name === "name"
            ? cleanName(capitalizeEachWord(value))
            : name === "matric"
            ? cleanMatric(value)
            : value,
      };

      if (name === "password" || name === "confirmpassword") {
        setMismatch(
          newFormData.password !== newFormData.confirmpassword &&
            newFormData.confirmpassword.length > 0
            ? true
            : false
        );
        setCheckProgress(passwordStrength(newFormData.password));
      }

      if (type === "file" && files[0]) {
        if (files[0].size <= 512000 && checkExtension(files[0].name)) {
          setImgErr({ name: "", reason: "", status: true });
          return newFormData;
        } else {
          if (files[0].size > 512000) {
            setImgErr({ name, reason: "size", status: false });
          } else if (!checkExtension(files[0].name)) {
            setImgErr({ name, reason: "format", status: false });
          }
          return oldFormData;
        }
      } else {
        return newFormData;
      }
    });
  }

  function success() {
    setStatus({
      status: "success",
      type: "success",
      message: "Registration Completed. Redirecting...",
    });
    setTimeout(() => {
      setStatus({ status: "", type: "", message: "" });
      navigate("/login/teacher", { replace: true });
    }, 3000);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (!(!imgErr.status || mismatch)) {
        setUploading(true);
        const regData = {
          matric: formData.matric,
          email: formData.email,
          Class: formData.class,
          centre: formData.centre,
          image: formData.image,
          password: formData.password,
          confirmpassword: formData.confirmpassword,
        };
        const res = await teacherRegistration(regData);
        if (res === "Registered") {
          if (typeof formData.image === "object") {
            setUstate("Uploading...");
            const interv = setInterval(() => {
              setUprogess((oldProgress) => {
                if (oldProgress >= 100) {
                  clearInterval(interv);
                }
                const rand = Math.floor(Math.random() * 25 + 15);
                const add = oldProgress + rand;
                return add > 100 ? 100 : add;
              });
            }, 500);
          }
          setTimeout(
            () => {
              setUprogess(0);
              success();
            },
            typeof formData.image === "object" ? 3000 : 1000
          );
        } else if (res === "Exists") {
          setStatus({
            status: "exists",
            type: "failed",
            message: "You are already Registered",
          });
          setUploading(false);
        } else if (res === "E-Mail") {
          setStatus({
            status: "e-mail",
            type: "failed",
            message: "Wrong E-Mail",
          });
          setUploading(false);
        } else if (res === "Matric") {
          setStatus({
            status: "e-mail",
            type: "failed",
            message: "Wrong Matric Number",
          });
          setUploading(false);
        } else if (res === "Mismatch") {
          setStatus({
            status: "Mismatch",
            type: "failed",
            message: "Password Mismatched",
          });
          setUploading(false);
        } else if (res === "Not Found") {
          setStatus({
            status: "Not Found",
            type: "failed",
            message: "Teacher not Found",
          });
          setUploading(false);
        } else {
          setStatus({
            status: "error",
            type: "failed",
            message: "Registration Failed",
          });
          setUploading(false);
        }
      }
    } catch (error) {
      setStatus({
        status: "error",
        type: "failed",
        message: "Registration Failed",
      });
      setUploading(false);
    }
  }

  function toggleShow() {
    setShowp((oldShowp) => !oldShowp);
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  useEffect(() => {
    getCentresDirect()
      .then((data) => setCentres(data))
      .catch((err) => err);
  }, []);

  useEffect(() => {
    if (formData.centre !== "") {
      getCentreClassesDirect(formData.centre)
        .then((data) => setClasses(data))
        .catch((err) => err);
    }
  }, [formData.centre]);

  const selectCentres = centres.map((cent) => (
    <option key={nanoid()} value={cent}>
      {cent}
    </option>
  ));
  const selectTclasses = classes.map((tclass) => (
    <option key={nanoid()} value={tclass}>
      {tclass}
    </option>
  ));

  return (
    <section className="contactform registration teacherreg">
      <div className="formwrapper">
        <div
          className={`wrapper`}
          style={{ height: status.message !== "" ? "700px" : "650px" }}
        >
          <div className="form-box login">
            <h2>Teacher Registration</h2>
            <form onSubmit={handleSubmit}>
              {status.message !== "" && (
                <p className="status-wrapper" id="status-wrapper">
                  <span className={`status-message ${status.type}`}>
                    {status.message}
                  </span>
                  <LiaTimesCircle
                    className="status-close"
                    onClick={() => {
                      setStatus({ status: "", type: "", message: "" });
                    }}
                  />
                </p>
              )}
              <div className="input-box">
                <span className="icon">
                  <FaIdCard />
                </span>
                <input
                  readOnly={uploading}
                  placeholder=" "
                  type="text"
                  name="matric"
                  style={{ textTransform: "uppercase" }}
                  value={formData.matric}
                  onChange={handleChange}
                  autoComplete="off"
                  pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}(-[A-Za-z])?/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{12}[A-Za-z]{2}|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$"
                  title="RUN/xxx/yy/yyyy | JAMB Number"
                  maxLength={18}
                  required
                />
                <label>Matric Number</label>
              </div>
              <div className="input-box">
                <span className="icon">
                  <FaEnvelope />
                </span>
                <input
                  readOnly={uploading}
                  placeholder=" "
                  type="email"
                  name="email"
                  value={formData.email}
                  minLength={13}
                  maxLength={50}
                  onChange={handleChange}
                  autoComplete="off"
                  required
                />
                <label>E-Mail</label>
              </div>
              {/* <div className="input-box">
                <span className="icon">
                  <FaImage />
                </span>
                <input
                  readOnly={uploading}
                  type="file"
                  name="image"
                  onChange={handleChange}
                  accept="image/jpg, image/jpeg image/png image/heic"
                />
                <label>
                  A Picture of You{" "}
                  <span style={{ fontSize: "0.7em" }}>(max = 500kb)</span>
                </label>
                {imgErr.name === "image" &&
                  imgErr.reason === "size" &&
                  !imgErr.status && <p>Max Size Exceeded</p>}
                {imgErr.name === "image" &&
                  imgErr.reason === "format" &&
                  !imgErr.status && <p>Invalid File Format</p>}
              </div> */}
              <div className="input-box">
                <select
                  disabled={uploading}
                  name="centre"
                  value={formData.centre}
                  onChange={handleChange}
                  className="inputs"
                  required
                >
                  <option value="" disabled>
                    Choose one...
                  </option>
                  {selectCentres}
                </select>
                <label>
                  <FaChurch /> Centre
                </label>
              </div>
              <div className="input-box">
                <select
                  disabled={uploading}
                  name="class"
                  value={formData.class}
                  onChange={handleChange}
                  className="inputs"
                  required
                >
                  <option value="" disabled>
                    Choose one...
                  </option>
                  {formData.centre === "" && (
                    <option value="" disabled>
                      Select Centre
                    </option>
                  )}
                  {selectTclasses}
                </select>
                <label>
                  <FaChalkboardUser /> Class
                </label>
              </div>
              <div
                className="input-box"
                style={{
                  borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`,
                }}
              >
                <span className="icon">
                  {!showp && (
                    <FaEye
                      style={{ cursor: "pointer" }}
                      className="ico"
                      onClick={toggleShow}
                    />
                  )}
                  {showp && (
                    <FaEyeSlash
                      style={{ cursor: "pointer" }}
                      className="ico"
                      onClick={toggleShow}
                    />
                  )}
                </span>
                <input
                  readOnly={uploading}
                  placeholder=" "
                  maxLength={30}
                  type={showp ? "text" : "password"}
                  name="password"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                  className="password-input"
                  value={formData.password}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
                <label>
                  <FaLock /> Password
                </label>
              </div>
              <div
                className="input-box"
                style={{
                  borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`,
                }}
              >
                <span className="icon">
                  {!showp && (
                    <FaEye
                      style={{ cursor: "pointer" }}
                      className="ico"
                      onClick={toggleShow}
                    />
                  )}
                  {showp && (
                    <FaEyeSlash
                      style={{ cursor: "pointer" }}
                      className="ico"
                      onClick={toggleShow}
                    />
                  )}
                </span>
                <input
                  readOnly={uploading}
                  placeholder=" "
                  maxLength={30}
                  type={showp ? "text" : "password"}
                  name="confirmpassword"
                  value={formData.confirmpassword}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                  className="password-input"
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
                <label>
                  <FaHandshake /> Confirm Password
                </label>
              </div>
              <span className="password-note">
                <div id="strength">
                  <div
                    id="strength-bar"
                    style={{
                      width: checkProgress.strength,
                      backgroundColor: checkProgress.color,
                    }}
                  ></div>
                </div>
                <i>
                  At Least:
                  <ul>
                    <li className={checkProgress.upper ? "complete" : null}>
                      <span> 1 Upper Case</span>
                    </li>
                    <li className={checkProgress.lower ? "complete" : null}>
                      <span> 1 Lower Case</span>
                    </li>
                    <li className={checkProgress.number ? "complete" : null}>
                      <span> 1 Number</span>
                    </li>
                    <li className={checkProgress.special ? "complete" : null}>
                      <span> 1 Special Character</span>
                    </li>
                    <li className={checkProgress.length ? "complete" : null}>
                      <span> 6 Characters</span>
                    </li>
                  </ul>
                </i>
              </span>
              <button
                type="submit"
                disabled={
                  !imgErr.status ||
                  mismatch ||
                  uploading ||
                  (checkProgress.strength !== "0%" &&
                    checkProgress.strength !== "100%")
                }
                className="btn"
              >
                {uploading ? (
                  uprogress === 100 ? (
                    "Finishing..."
                  ) : (
                    <FaSpinner className="spinner" />
                  )
                ) : (
                  "Submit"
                )}
              </button>
              {uploading && uprogress !== 0 && (
                <div className="upload-status">
                  <div id="upload-progress">
                    <div
                      id="upload-progress-bar"
                      className="loader-item"
                      style={{
                        width: uprogress + "%",
                        backgroundImage:
                          uprogress !== 100
                            ? "linear-gradient(110deg, #002F63 8%, #1257a7 18%, #002F63 33%)"
                            : "linear-gradient(110deg, green 8%, yellowgreen 18%, green 33%)",
                      }}
                    ></div>
                    <label>{Math.floor(uprogress)}%</label>
                  </div>
                  <div
                    style={{
                      fontSize: "0.8em",
                      fontFamily: "Georgia, 'Times New Roman', Times, serif",
                    }}
                  >
                    {uprogress === 100 ? (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontFamily:
                            "Georgia, 'Times New Roman', Times, serif",
                        }}
                      >
                        <LiaCheckCircle
                          style={{
                            fontSize: "1.2em",
                            marginRight: "2px",
                            color: "green",
                          }}
                        />
                        Upload Completed
                      </span>
                    ) : (
                      ustate
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TeacherRegistration;
