import React, { useEffect, useState } from "react";
import { FaKey, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  deleteTeacher,
  getTeacher,
  showSwal,
  stripSpace,
} from "../../AppManager";
import { nanoid } from "nanoid";

function AdminDelete() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const { record, setRecord } = useOutletContext();
  const navigate = useNavigate();
  const [crkey, setCrkey] = useState("");
  const [keyword, setKeyword] = useState("loading...");
  const [status, setStatus] = useState({ status: "", type: "", message: "" });
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teacher, setTeacher] = useState({
    name: "",
    matric: "",
    trainingClass: "",
    class: "",
    centre: "",
    position: "",
    image: "",
    imgname: "",
  });
  const returnPath = pathname.slice(0, pathname.indexOf(`/${id}`));

  useEffect(() => {
    try {
      const data = getTeacher(id, record);
      if (data) {
        setTeacher(data);
        const trimname = stripSpace(data.name);
        setKeyword(
          trimname.length >= 10
            ? trimname.slice(0, 10)
            : trimname.padEnd("10", nanoid(10 - trimname.length))
        );
      } else {
        if (status.message !== "Deleted Successfully. Redirecting...") {
          showSwal("error", "Teacher not Found");
          navigate(returnPath, { replace: true });
        }
      }
    } catch (error) {
      if (status.message !== "Deleted Successfully. Redirecting...") {
        showSwal("error", "Teacher not Found");
        navigate(returnPath, { replace: true });
      }
    }
  }, [id, record, navigate, returnPath, status]);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "crkey") {
      setCrkey(value);
      if (value !== "" && value !== keyword) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (crkey === keyword) {
        const res = await deleteTeacher({
          matric: teacher["matric"],
          image: teacher.image,
          Class: teacher.class,
          position: teacher.position,
        });
        if (res === "Deleted") {
          setRecord((oldRecord) => {
            let updatedTeacher = [
              ...oldRecord[teacher.centre][teacher.trainingClass].classes,
            ];
            for (let k = 0; k < updatedTeacher.length; k++) {
              const Class = updatedTeacher[k];
              if (Class.name === teacher.class) {
                updatedTeacher[k] =
                  teacher.position.toLowerCase() === "teacher"
                    ? {
                        ...Class,
                        teacher: "",
                        "teacher matric": "",
                        "teacher email": "",
                        "teacher image": "user.png",
                      }
                    : {
                        ...Class,
                        "assistant teacher": "",
                        "assistant matric": "",
                        "assistant email": "",
                        "assistant image": "user.png",
                      };
              }
            }
            return {
              ...oldRecord,
              [teacher.centre]: {
                ...oldRecord[teacher.centre],
                [teacher.trainingClass]: {
                  ...oldRecord[teacher.centre][teacher.trainingClass],
                  classes: updatedTeacher,
                },
              },
            };
          });
          setTimeout(() => {
            setStatus({
              status: "success",
              type: "success",
              message: "Deleted Successfully. Redirecting...",
            });
            setCrkey("");
            setTimeout(() => {
              navigate(returnPath, { replace: true });
            }, 2000);
          }, 1000);
        } else if (res === "Not Found") {
          showSwal("error", "Teacher not Found");
          navigate(returnPath, { replace: true });
        } else {
          setTimeout(() => {
            setStatus({
              status: "error",
              type: "failed",
              message: "Delete Failed",
            });
            setLoading(false);
          }, 1000);
        }
      } else {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "error",
            message: "Incorrect Key",
          });
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setStatus({ status: "error", type: "failed", message: "Delete Failed" });
      setLoading(false);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  useEffect(() => {
    document.title = "Delete Teacher";
  }, []);

  return (
    <div className="attendanceform">
      <div className="formwrapper">
        <div
          className="wrapper"
          style={{
            height: status.message !== "" ? "350px" : "330px",
            color: "#002F63",
          }}
        >
          <div className="form-box login">
            <h2>Delete Teacher</h2>
            <form onSubmit={handleSubmit}>
              {status.message !== "" && (
                <p className="status-wrapper" id="status-wrapper">
                  <span className={`status-message ${status.type}`}>
                    {status.message}
                  </span>
                  <LiaTimesCircle
                    className="status-close"
                    onClick={() => {
                      setStatus({ status: "", type: "", message: "" });
                    }}
                  />
                </p>
              )}
              <p style={{ fontSize: "16px", marginTop: "5px" }}>
                Type <strong>{keyword}</strong> to complete removal process
              </p>
              <div className="input-box">
                <span className="icon">
                  <FaKey />
                </span>
                <input
                  readOnly={loading}
                  type="text"
                  onChange={handleChange}
                  value={crkey}
                  autoComplete="off"
                  maxLength={10}
                  minLength={10}
                  required
                  className="input"
                  id="crkey"
                  name="crkey"
                  placeholder=" "
                />
                <label>Key</label>
              </div>
              <button
                className="btn"
                disabled={disabled || loading || keyword === "loading..."}
              >
                {loading ? <FaSpinner className="spinner" /> : "Remove"}
              </button>
              <p style={{ fontSize: "14px", marginTop: "5px" }}>
                <strong>Note:</strong> This action is final and irreversible.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDelete;
