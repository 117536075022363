import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { FaUserPen } from "react-icons/fa6";
import {
  capitalizeEachWord,
  extractMatricDigits,
  getClassDetails,
  getClassRecord,
  showSwal,
  updateClassAdmission,
  updateCurrentClass,
} from "../../AppManager";
import ConfirmAction from "../../components/ConfirmAction";

function AdminClassDetails() {
  const { record, setRecord, setClassRecord, setCurrClass, currUser } =
    useOutletContext();
  const { Class } = useParams();
  const [classDetail, setClassDetail] = useState({
    name: "",
    teacher: "ABC",
    "teacher matric": "RUN/ABC/12/3456",
    "assistant teacher": "",
    "assistant matric": "RUN/ABC/12/3456",
    students: "",
    centre: "",
    trainingClass: "",
    admission: true,
    currentClass: false,
    "teacher img": "user.png",
    location: "",
  });
  const returnPath = "/admin/overview/classes";
  const navigate = useNavigate();
  const [close, setClose] = useState(true);
  const [actionEvent, setActionEvent] = useState({});
  const [page, setPage] = useState(true);
  const [pageFlip, setPageFlip] = useState(true);

  useEffect(() => {
    const cdet = getClassDetails(Class, record);
    if (cdet && cdet.name) {
      setClassDetail(cdet);
    } else {
      showSwal("error", "Class not Found");
      navigate(returnPath, { replace: true });
    }
  }, [Class, record, navigate, returnPath]);

  useEffect(() => {
    if (classDetail.teacher !== "ABC") {
      getClassRecord(classDetail.trainingClass, classDetail.name)
        .then((data) => setClassRecord(data))
        .catch(() => {
          showSwal("error", "Class not Found");
          navigate(returnPath, { replace: true });
        });
      setCurrClass(classDetail.name);
    }
  }, [setCurrClass, classDetail, setClassRecord, returnPath, navigate]);

  async function toggle(e) {
    const { name, type, checked } = e.target;
    if (name === "admission" && type === "checkbox") {
      const res = await updateClassAdmission({
        name: classDetail.name,
        admission: checked,
      });
      if (res === "Updated") {
        setClassDetail((oldClassDetail) => {
          return { ...oldClassDetail, [name]: checked };
        });
        setRecord((oldRecord) => {
          let lasses =
            oldRecord[classDetail.centre][classDetail.trainingClass].classes;
          for (let l = 0; l < lasses.length; l++) {
            let currC = lasses[l];
            if (currC.name === classDetail.name) {
              lasses[l] = { ...currC, [name]: checked };
            }
          }
          return {
            ...oldRecord,
            [classDetail.centre]: {
              ...oldRecord[classDetail.centre],
              [classDetail.trainingClass]: {
                ...oldRecord[classDetail.centre][classDetail.trainingClass],
                classes: lasses,
              },
            },
          };
        });
      } else {
        showSwal("error", "Failed");
      }
    }
  }

  function toggleClass(e) {
    const { name, type, checked } = e.target;
    setActionEvent({ name, type, checked });
    setClose(false);
  }

  async function toggleClassConfirmed({ name, type, checked }) {
    if (name === "currentClass" && type === "checkbox") {
      const res = await updateCurrentClass({
        name: classDetail.name,
        currentClass: checked,
      });
      if (res === "Updated") {
        setClassDetail((oldClassDetail) => {
          return { ...oldClassDetail, [name]: checked };
        });
        setRecord((oldRecord) => {
          let lasses =
            oldRecord[classDetail.centre][classDetail.trainingClass].classes;
          for (let l = 0; l < lasses.length; l++) {
            let currC = lasses[l];
            if (currC.name === classDetail.name) {
              lasses[l] = { ...currC, [name]: checked, students: 0 };
            }
          }
          return {
            ...oldRecord,
            [classDetail.centre]: {
              ...oldRecord[classDetail.centre],
              [classDetail.trainingClass]: {
                ...oldRecord[classDetail.centre][classDetail.trainingClass],
                classes: lasses,
              },
            },
          };
        });
      } else if (res === "Exists") {
        showSwal("error", "Record Exists");
      } else if (res === "Empty") {
        showSwal("error", "Record Empty");
      } else {
        showSwal("error", "Failed");
      }
    }
  }

  function togglePage() {
    setPageFlip((oldPageFlip) => !oldPageFlip);
    setTimeout(() => {
      setPage((oldPage) => !oldPage);
    }, 250);
  }

  useEffect(() => {
    document.title = classDetail?.name ? classDetail.name : "Class Details";
  }, [classDetail]);

  return (
    <div className="details-card-wrapper">
      {!close && (
        <ConfirmAction
          currUser={currUser}
          setClose={setClose}
          confirmed={() => {
            toggleClassConfirmed(actionEvent);
          }}
        />
      )}
      {!classDetail?.name && (
        <div
          className="loader-item"
          style={{ width: "220px", height: "280px" }}
        >
          <div className="ruc-watermark">
            <div>
              <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
              <p>RUC Training Unit</p>
            </div>
          </div>
        </div>
      )}
      {classDetail?.name && (
        <div className={`details-card ${pageFlip ? "" : "rotate"}`}>
          {page && (
            <div className="front" onClick={togglePage}>
              <div className="details-img">
                <img
                  onError={(e) => {
                    if (e.target.src !== "/user.png") {
                      e.target.onerror = null;
                      e.target.src = "/user.png";
                    }
                  }}
                  src="/images/classroom.jpg"
                  alt={capitalizeEachWord(classDetail.name)}
                />
              </div>
              <span>{capitalizeEachWord(classDetail.name)}</span>
              <span>{capitalizeEachWord(classDetail.trainingClass)}</span>
              <span className="manage" style={{ justifyContent: "center" }}>
                <Link to={`/admin/overview/editclass/${classDetail.name}`}>
                  <FaUserPen style={{ fontSize: "1.1em" }} />
                </Link>
              </span>
            </div>
          )}
          {!page && (
            <div className="back" onClick={togglePage}>
              <div>
                <h3>Teacher</h3>
                <span>
                  <Link
                    to={
                      "teacher/" +
                      extractMatricDigits(classDetail["teacher matric"])
                    }
                  >
                    {capitalizeEachWord(classDetail["teacher"])}
                  </Link>
                </span>
              </div>
              <div>
                <h3>Assistant Teacher</h3>
                <span>
                  <Link
                    to={
                      "teacher/" +
                      extractMatricDigits(classDetail["assistant matric"])
                    }
                  >
                    {capitalizeEachWord(classDetail["assistant teacher"])}
                  </Link>
                </span>
              </div>
              <div>
                <h3>Centre</h3>
                <span>{classDetail.centre}</span>
              </div>
              <div>
                <h3>Training Class</h3>
                <span>{classDetail.trainingClass}</span>
              </div>
              <div>
                <h3>Location</h3>
                <span>{classDetail.location}</span>
              </div>
              <div>
                <h3>Students</h3>
                <span>
                  <Link to="students" style={{ color: "inherit" }}>
                    {classDetail.students}
                  </Link>
                </span>
              </div>
              <div>
                <h3>Admission</h3>
                <span className="checkbox-wrapper">
                  <input
                    className="tgl tgl-skewed"
                    id="cb3-8"
                    name="admission"
                    type="checkbox"
                    checked={classDetail.admission}
                    onChange={toggle}
                  />
                  <label
                    className="tgl-btn"
                    data-tg-off="CLOSED"
                    data-tg-on="OPEN"
                    htmlFor="cb3-8"
                    style={{
                      transform: "skew(-10deg)",
                      fontSize: "0.8em",
                      width: "7em",
                    }}
                  ></label>
                </span>
              </div>
              <div>
                <h3>Class</h3>
                <span className="checkbox-wrapper">
                  <input
                    className="tgl tgl-skewed"
                    id="cb3-9"
                    name="currentClass"
                    type="checkbox"
                    checked={classDetail.currentClass}
                    onChange={toggleClass}
                  />
                  <label
                    className="tgl-btn tgl-btn2"
                    data-tg-off="START"
                    data-tg-on="ONGOING"
                    htmlFor="cb3-9"
                    style={{
                      transform: "skew(-10deg)",
                      fontSize: "0.8em",
                      width: "7em",
                    }}
                  ></label>
                </span>
              </div>
            </div>
          )}
          <div className="ruc-watermark">
            <div>
              <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
              <p>RUC Training Unit</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminClassDetails;
