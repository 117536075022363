import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

function BaptismalClass() {
  useEffect(() => {
    document.title = "Baptismal Class";
  }, []);

  return (
    <section className="classes-info">
      <h1 className="heading1">Baptismal Class</h1>
      <div>
        <img src="/images/baptismal class.png" alt="Baptismal Class" />
        <div>
          <p>
            Water Baptism is reserved for a person who consciously and knowingly
            understands his/her decision to receive and follow Jesus Christ as
            their Lord and Saviour.
          </p>
          <p>
            The word baptize comes from a Greek word "baptizo", which means to
            submerge or immerse. Therefore, we baptize by full immersion under
            water. Baptism is an immersion into something. In this case, it's a
            public expression of our union or identification in Jesus Christ.
          </p>
          <p>
            It should be understood that water baptism is not a means of
            salvation, but rather an external expression of an inward work that
            has already taken place. In other words, one must be born again
            first, before being water baptized. Due to this reason, the
            completion of the believers class is a requirement for this class.
          </p>
          <p>
            When we go under the water, we publicly declare that as a born again
            child of God, our old life is now dead to sin. We are buried with
            Christ. Then when we come up out of the water, we are declaring that
            we are resurrected in the newness of life in Christ, and we are no
            longer slaves of sin, but now alive unto God prepared and determined
            to live a life that is fully committed to obeying and following God!
          </p>
          <p>
            This class therefore is a preparation for our new life in Christ
            after we have being baptized.
          </p>
        </div>
      </div>
      <NavLink to="/register" state={{ trainingClass: "Baptismal Class" }}>
        <button className="btn">Join Class</button>
      </NavLink>
    </section>
  );
}

export default BaptismalClass;
