import React from "react";
import ClassCard from "../../components/ClassCard";

export default function EventClasses() {
  return (
    <>
      <h2 className="heading2">Event Center Classes Location</h2>
      <div>
        <ClassCard
          name={"Believers Class"}
          to={""}
          image="/images/event_center.jpg"
        />
        <span>
          <strong>Location:</strong> Event Center
        </span>
      </div>
      <div>
        <ClassCard
          name={"Baptismal Class"}
          to={""}
          image="/images/event_center.jpg"
        />
        <span>
          <strong>Location:</strong> Event Center
        </span>
      </div>
    </>
  );
}
