import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalizeEachWord, getTeacherDirect, showSwal } from "../AppManager";
import { clearUser, getTokenDetails } from "../Auth";

function ProfileTeacher() {
  const navigate = useNavigate();
  const [page, setPage] = useState(true);
  const [pageFlip, setPageFlip] = useState(true);
  const [teacher, setTeacher] = useState({
    name: "",
    matric: "",
    class: "",
    location: "",
    trainingClass: "",
    centre: "",
    position: "",
    image: "user.png",
  });
  const { state } = useLocation();
  const { position } = getTokenDetails();

  useEffect(() => {
    if (position !== "student") {
      clearUser();
      navigate("/");
    }
  }, [position, navigate]);

  useEffect(() => {
    document.title = teacher?.name ? teacher.name : "Teacher Profile";
  }, [teacher]);

  useEffect(() => {
    try {
      if (state && state.teacherMatric && state.class) {
        getTeacherDirect(state.teacherMatric, state.class)
          .then((data) => setTeacher(data))
          .catch(() => {
            showSwal("error", "Teacher not Found");
            navigate("/profile", { replace: true });
          });
      } else {
        showSwal("error", "Teacher not Found");
        navigate("/profile", { replace: true });
      }
    } catch (error) {
      showSwal("error", "Teacher not Found");
      navigate("/profile", { replace: true });
    }
  }, [navigate, state]);

  function togglePage() {
    setPageFlip((oldPageFlip) => !oldPageFlip);
    setTimeout(() => {
      setPage((oldPage) => !oldPage);
    }, 250);
  }

  return (
    <div className="profile" style={{ flexDirection: "column" }}>
      <h1
        className="heading1"
        style={{ marginTop: "10px", textAlign: "center" }}
      >
        Teacher Profile
      </h1>
      {!teacher?.name && (
        <div
          className="loader-item"
          style={{ width: "220px", height: "280px", margin: "20px 0" }}
        >
          <div className="ruc-watermark">
            <div>
              <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
              <p>RUC Training Unit</p>
            </div>
          </div>
        </div>
      )}
      {teacher?.name && (
        <div className="details-card-wrapper">
          <div className={`details-card ${pageFlip ? "" : "rotate"}`}>
            {page && (
              <div className="front" onClick={togglePage}>
                <div className="details-img">
                  <img
                    onError={(e) => {
                      if (e.target.src !== "/user.png") {
                        e.target.onerror = null;
                        e.target.src = "/user.png";
                      }
                    }}
                    src={`/images/teachers/${teacher.image}`}
                    alt={capitalizeEachWord(teacher.name)}
                  />
                </div>
                <span>{capitalizeEachWord(teacher.name)}</span>
                <span>{capitalizeEachWord(teacher.position)}</span>
              </div>
            )}
            {!page && (
              <div className="back" onClick={togglePage}>
                <div>
                  <h3>Name</h3>
                  <span>{capitalizeEachWord(teacher.name)}</span>
                </div>
                <div>
                  <h3>E-Mail</h3>
                  <span style={{ wordBreak: "break-all" }}>
                    <a href={`mailto: ${teacher.email}`}>{teacher.email}</a>
                  </span>
                </div>
                <div>
                  <h3>Centre</h3>
                  <span>{teacher.centre}</span>
                </div>
                <div>
                  <h3>Training Class</h3>
                  <span>{teacher.trainingClass}</span>
                </div>
                <div>
                  <h3>Class</h3>
                  <span>{teacher.class}</span>
                </div>
                <div>
                  <h3>Location</h3>
                  <span>{teacher.location}</span>
                </div>
              </div>
            )}
            <div className="ruc-watermark">
              <div>
                <img
                  src="/images/Bible Study Icon.png"
                  alt="RUC Training Unit"
                />
                <p>RUC Training Unit</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileTeacher;
