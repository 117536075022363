import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

function WorkersInTraining() {
  useEffect(() => {
    document.title = "Workers in Training";
  }, []);

  return (
    <section className="classes-info">
      <h1 className="heading1">Workers in Training</h1>
      <div>
        <img src="/images/workers in training.png" alt="Workers in Training" />
        <div>
          <p>
            This class is a call to higher ground for those who have given their
            lives to Christ and are taking a further step to commit to God on a
            higher level to work with God in His vineyard. It is a call to a
            life of loyalty to the Lord.
          </p>
          <p>
            It involves a regular training scheme where candidates who are
            qualified to become workers are trained weekly on discipleship and
            teaching techniques to equip one to disciple a young convert. The
            goals of the training include the following:
          </p>
          <p>
            <strong>1)</strong> To produce a mature group of believers who will
            be able to teach others the good news at least sufficiently to lead
            them to Christ <br />
            <strong>2)</strong> To produce believers who are efficiently able to
            teach the scriptures and produce such disciples who will be as
            strong as themselves <br />
            <strong>3)</strong> To prepare workers of the church with the
            knowledge of the administration of the church that will make them
            effectively useful in the area chosen for their services such as
            choristers, ushers, evangelists, teachers, etc.
          </p>
          <p>
            It is expected that a believer undergoing workers in training must
            have passed through the Believers Class, the Baptismal Class and be
            baptised by full immersion in water. Therefore, a worker in training
            should have achieved the assurance of salvation, solutions to the
            struggles of his faith, be able to defend his newfound faith and be
            prepared with eagerness to advance with Christ in his day-to-day
            living and in studying and doing the Word of God.
          </p>
          <p>
            Are you born again? Are you a potential worker for the Lord? Have
            you got talents or gifts you can use for the glory of God? This is
            the class for you. What are you waiting for? Register your name to
            join our next class.
          </p>
        </div>
      </div>
      <NavLink to="/register" state={{ trainingClass: "Workers in Training" }}>
        <button className="btn">Join Class</button>
      </NavLink>
    </section>
  );
}

export default WorkersInTraining;
