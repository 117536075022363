import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FaIdCard, FaSpinner } from "react-icons/fa6";
import {
  createToday,
  generateAttendance,
  getCurrTimeInfo,
  showSwal,
  stripSpace,
  todayExists,
} from "../../AppManager";
import { downloadBaseURL } from "../../Auth";

function SignIn() {
  const { classRecord, user } = useOutletContext();
  const [idnum, setIdnum] = useState("");
  const [download, setDownload] = useState({ state: "", link: "" });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Sign In";
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "idnum") {
      setIdnum(stripSpace(value.toUpperCase()));
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    try {
      if (
        Object.hasOwnProperty.call(classRecord, idnum) ||
        Object.hasOwnProperty.call(classRecord, idnum.padStart(5, "0")) ||
        (idnum.length === 5 &&
          idnum[0] === "0" &&
          Object.hasOwnProperty.call(classRecord, idnum.slice(1)))
      ) {
        const uidnum = Object.hasOwnProperty.call(classRecord, idnum)
          ? idnum
          : idnum.length === 5 &&
            idnum[0] === "0" &&
            Object.hasOwnProperty.call(classRecord, idnum.slice(1))
          ? idnum.slice(1)
          : idnum.padStart(5, "0");
        const member = classRecord[uidnum];
        if (!todayExists(classRecord)) {
          createToday(classRecord);
        }
        const status = member[getCurrTimeInfo().today];
        if (status !== "ABSENT") {
          showSwal("info", "Already signed in");
        } else {
          navigate(`/overview/attendance/signin/confirm`, {
            state: { permission: true, id: uidnum },
          });
        }
      } else {
        showSwal("error", "Student not Found");
      }
    } catch (error) {
      showSwal("error", "Student not Found");
    }
  }

  function downloadAttendance() {
    generateAttendance("current", user.class)
      .then((filename) => {
        if (filename === "Empty") {
          showSwal("error", "Attendance Download Failed", "No Record Found");
        } else {
          setDownload({ state: "generating", link: "" });
          setTimeout(() => {
            setDownload({
              state: "ready",
              link: `${downloadBaseURL}${filename}`,
            });
          }, 3000);
        }
      })
      .catch((err) => showSwal("error", "Attendance Download Failed"));
  }

  return (
    <div className="formwrapper">
      <div className="wrapper">
        <div className="form-box login">
          <h2>Sign In</h2>
          <form onSubmit={handleSubmit}>
            <div className="input-box">
              <span className="icon">
                <FaIdCard />
              </span>
              <input
                type="text"
                onChange={handleChange}
                value={idnum}
                autoComplete="off"
                pattern="\d{4,5}|[0-9]{5}[A-Za-z]{2}"
                minLength={4}
                maxLength={7}
                required
                name="idnum"
                style={{ textTransform: "uppercase" }}
                placeholder=" "
              />
              <label>Matric Number</label>
            </div>
            <button type="submit" className="btn">
              Submit
            </button>
            <div
              style={{
                textAlign: "center",
                color: "#002F63",
                marginTop: "10px",
              }}
            >
              {download.state === "" ? (
                <label className="download-option" onClick={downloadAttendance}>
                  Generate Attendance
                </label>
              ) : download.state === "ready" ? (
                <a
                  className="download-option"
                  download
                  href={download.link}
                  onClick={() => {
                    setDownload({ state: "", link: "" });
                  }}
                >
                  Download
                </a>
              ) : (
                <label className="download-option">
                  <FaSpinner className="spinner" />
                </label>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
