import React, { useEffect, useState } from "react";
import {
  FaEye,
  FaEyeSlash,
  FaIdCard,
  FaLock,
  FaPaperPlane,
  FaSpinner,
} from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decodeString, forgottenPassword, login } from "../../Auth";
import { passwordResetMail } from "../../AppManager";

function Teacher() {
  const params = useSearchParams()[0];
  const next = params.get("next");
  const message = params.get("message");
  const navigate = useNavigate();
  const [forgotPage, setForgotPage] = useState(false);
  const [formData, setFormData] = useState(() => {
    return {
      matric:
        localStorage.getItem("jxnNS4uenk23k") !== null
          ? decodeString(localStorage.getItem("jxnNS4uenk23k"))
          : "",
      password:
        localStorage.getItem("jnxkj5rn32kn39nd") !== null
          ? decodeString(localStorage.getItem("jnxkj5rn32kn39nd"))
          : "",
      remember: localStorage.getItem("jxnNS4uenk23k") !== null ? true : false,
    };
  });
  const [forgotMatric, setForgotMatric] = useState("");
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: message ? message : "",
  });
  const [loading, setLoading] = useState(false);
  const [showp, setShowp] = useState(false);
  const [redirection, setRedirection] = useState("");

  useEffect(() => {
    if (redirection !== "") {
      navigate(redirection.path, redirection.options);
    }
  }, [redirection, navigate]);

  function handleChange(e) {
    const { name, type, value, checked } = e.target;
    setFormData((oldFormData) => {
      return { ...oldFormData, [name]: type === "checkbox" ? checked : value };
    });
  }

  function success() {
    setStatus({
      status: "success",
      type: "success",
      message: "Log-In Successful. Redirecting...",
    });
    if (!formData.remember) {
      setFormData({ matric: "", password: "", remember: "" });
    }
    setTimeout(() => {
      if (next) {
        setRedirection({ path: next });
      } else {
        setRedirection({ path: "/overview" });
      }
    }, 1000);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (
        formData.matric ===
          decodeString(localStorage.getItem("jxnNS4uenk23k")) &&
        !formData.remember
      ) {
        localStorage.removeItem("jxnNS4uenk23k");
        localStorage.removeItem("jnxkj5rn32kn39nd");
      }
      const res = await login(
        formData.matric,
        "teacher",
        formData.password,
        formData.remember
      );
      if (res === "Success") {
        success();
      } else if (res === "Incorrect") {
        setStatus({
          status: "Incorrect",
          type: "failed",
          message: "Matric/Password Mismatch",
        });
        setLoading(false);
      } else {
        setStatus({ status: "error", type: "failed", message: "Login Failed" });
        setLoading(false);
      }
    } catch (error) {
      setStatus({ status: "error", type: "failed", message: "Login Failed" });
      setLoading(false);
    }
  }

  function toggleShow() {
    setShowp((oldShowp) => !oldShowp);
  }

  function handleForgotChange(e) {
    const { name, value } = e.target;
    if (name === "forgotmatric") {
      setForgotMatric(value);
    }
  }

  async function handleForgotSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await forgottenPassword(forgotMatric, "teacher");
      if (res[0] === "Success") {
        const success = {
          status: "success",
          type: "success",
          message: "Password Reset Link sent to E-Mail",
        };
        const fail = {
          status: "failed",
          type: "failed",
          message: "Request Failed",
        };
        const reset = "";
        passwordResetMail(
          res[1],
          res[2],
          res[3],
          setStatus,
          setForgotMatric,
          setLoading,
          success,
          fail,
          reset
        );
      } else if (res[0] === "Not Found") {
        setStatus({
          status: "Not Found",
          type: "failed",
          message: "Matric not Registered",
        });
        setLoading(false);
      } else if (res[0] === "Exists") {
        setStatus({
          status: "Exists",
          type: "failed",
          message: "Previous Reset Link Unused",
        });
        setLoading(false);
      } else {
        setStatus({
          status: "failed",
          type: "failed",
          message: "Request Failed",
        });
        setLoading(false);
      }
    } catch (error) {
      setStatus({
        status: "failed",
        type: "failed",
        message: "Request Failed",
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  useEffect(() => {
    document.title = "Teacher Login";
  }, []);

  return (
    <section className="contactform loginform">
      <div className="formwrapper">
        <div className={`wrapper ${forgotPage ? "active-forgot" : ""}`}>
          <div className="form-box login">
            <h2>Teacher Login</h2>
            <form onSubmit={handleSubmit}>
              {status.message !== "" && (
                <p className="status-wrapper" id="status-wrapper">
                  <span className={`status-message ${status.type}`}>
                    {status.message}
                  </span>
                  <LiaTimesCircle
                    className="status-close"
                    onClick={() => {
                      setStatus({ status: "", type: "", message: "" });
                    }}
                  />
                </p>
              )}
              <div className="input-box">
                <span className="icon">
                  <FaIdCard />
                </span>
                <input
                  readOnly={loading}
                  placeholder=" "
                  type="text"
                  pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}(-[A-Za-z])?/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{12}[A-Za-z]{2}|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$"
                  title="RUN/xxx/yy/yyyy | JAMB Number"
                  name="matric"
                  maxLength={18}
                  autoComplete="off"
                  value={formData.matric}
                  onChange={handleChange}
                  style={{ textTransform: "uppercase" }}
                  required
                />
                <label>Matric Number</label>
              </div>
              <div className="input-box">
                <span className="icon">
                  {!showp && (
                    <FaEye
                      style={{ cursor: "pointer" }}
                      className="ico"
                      onClick={toggleShow}
                    />
                  )}
                  {showp && (
                    <FaEyeSlash
                      style={{ cursor: "pointer" }}
                      className="ico"
                      onClick={toggleShow}
                    />
                  )}
                </span>
                <input
                  readOnly={loading}
                  placeholder=" "
                  name="password"
                  type={showp ? "text" : "password"}
                  value={formData.password}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
                <label>
                  <FaLock /> Password
                </label>
              </div>
              <div className="remember-forgot">
                <label htmlFor="rememberme">
                  <input
                    readOnly={loading}
                    type="checkbox"
                    name="remember"
                    id="rememberme"
                    onChange={handleChange}
                    checked={formData.remember}
                  />{" "}
                  Remember Me
                </label>
                <span
                  className="forgot-link"
                  onClick={() => {
                    setForgotPage(true);
                    setStatus({ status: "", type: "", message: "" });
                  }}
                >
                  Forgot Password?
                </span>
              </div>
              <button type="submit" className="btn" disabled={loading}>
                {loading ? <FaSpinner className="spinner" /> : "Login"}
              </button>
            </form>
          </div>
          <div className="form-box forgotpassword">
            <h2>Forgot Password</h2>
            <form onSubmit={handleForgotSubmit}>
              {status.message !== "" && (
                <p className="status-wrapper" id="status-wrapper">
                  <span className={`status-message ${status.type}`}>
                    {status.message}
                  </span>
                  <LiaTimesCircle
                    className="status-close"
                    onClick={() => {
                      setStatus({ status: "", type: "", message: "" });
                    }}
                  />
                </p>
              )}
              <div className="input-box">
                <span className="icon">
                  <FaIdCard />
                </span>
                <input
                  readOnly={loading}
                  placeholder=" "
                  name="forgotmatric"
                  pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}(-[A-Za-z])?/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{12}[A-Za-z]{2}|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$"
                  title="RUN/xxx/yy/yyyy | JAMB Number"
                  maxLength={18}
                  style={{ textTransform: "uppercase" }}
                  type="text"
                  onChange={handleForgotChange}
                  value={forgotMatric}
                  autoComplete="off"
                  required
                />
                <label htmlFor="">Matric Number</label>
              </div>
              <button type="submit" className="btn" disabled={loading}>
                {loading ? (
                  <FaSpinner className="spinner" />
                ) : (
                  <>
                    <FaPaperPlane /> Send Link
                  </>
                )}
              </button>
              <div className="login-register">
                <p>
                  <span
                    className="return-link"
                    onClick={() => {
                      setForgotPage(false);
                      setStatus({ status: "", type: "", message: "" });
                    }}
                  >
                    Login
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Teacher;
