import React, { useEffect, useState } from "react";
import { FaChurch, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { useOutletContext } from "react-router-dom";
import {
  capitalizeEachWord,
  centreRegistration,
  cleanName,
} from "../../AppManager";

function AddCentre() {
  const { record, setRecord } = useOutletContext();
  const [centre, setCentre] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: "", type: "", message: "" });

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "centre") {
      setCentre(cleanName(capitalizeEachWord(value)));
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      if (!record.hasOwnProperty(centre)) {
        const res = await centreRegistration({ name: centre });
        if (res === "Registered") {
          setRecord((oldRecord) => {
            return { ...oldRecord, [centre]: {} };
          });
          setStatus({
            status: "success",
            type: "success",
            message: "Centre created Successfully",
          });
          setLoading(false);
          setCentre("");
        } else if (res === "Exists") {
          setStatus({
            status: "exists",
            type: "failed",
            message: "Centre already Exists",
          });
          setLoading(false);
        } else {
          setStatus({
            status: "error",
            type: "failed",
            message: "Creation Failed",
          });
          setLoading(false);
        }
      } else {
        setTimeout(() => {
          setStatus({
            status: "exists",
            type: "failed",
            message: "Centre already Exists",
          });
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      setStatus({
        status: "error",
        type: "failed",
        message: "Creation Failed",
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  useEffect(() => {
    document.title = "Add Centre";
  }, []);

  return (
    <div className="formwrapper" style={{ margin: "50px 0" }}>
      <div className="wrapper" style={{ height: "330px" }}>
        <div className="form-box login">
          <h2>Add Centre</h2>
          <form onSubmit={handleSubmit}>
            {status.message !== "" && (
              <p className="status-wrapper" id="status-wrapper">
                <span className={`status-message ${status.type}`}>
                  {status.message}
                </span>
                <LiaTimesCircle
                  className="status-close"
                  onClick={() => {
                    setStatus({ status: "", type: "", message: "" });
                  }}
                />
              </p>
            )}
            <div className="input-box">
              <span className="icon">
                <FaChurch />
              </span>
              <input
                readOnly={loading}
                type="text"
                onChange={handleChange}
                value={centre}
                autoComplete="off"
                required
                minLength={3}
                maxLength={20}
                pattern="[A-Za-z\- ]+"
                name="centre"
                placeholder=" "
              />
              <label>Centre Name</label>
            </div>
            <button type="submit" disabled={loading} className="btn">
              {loading ? <FaSpinner className="spinner" /> : "Create"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCentre;
