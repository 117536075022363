import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaLock, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { adminLogin } from "../../Auth";
import { useNavigate, useSearchParams } from "react-router-dom";

function Admin() {
  const params = useSearchParams()[0];
  const next = params.get("next");
  const message = params.get("message");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: message ? message : "",
  });
  const [loading, setLoading] = useState(false);
  const [showp, setShowp] = useState(false);
  const [redirection, setRedirection] = useState("");

  useEffect(() => {
    if (redirection !== "") {
      navigate(redirection.path, redirection.options);
    }
  }, [redirection, navigate]);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    }
  }

  function success() {
    setStatus({
      status: "success",
      type: "success",
      message: "Log-In Successful. Redirecting...",
    });
    setTimeout(() => {
      if (next) {
        setRedirection({ path: next });
      } else {
        setRedirection({ path: "/admin/overview" });
      }
    }, 1000);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await adminLogin(password);
      if (res === "Confirmed") {
        success();
      } else if (res === "Incorrect") {
        setStatus({
          status: "Incorrect",
          type: "failed",
          message: "Incorrect Password",
        });
        setLoading(false);
      } else {
        setStatus({ status: "error", type: "failed", message: "Login Failed" });
        setLoading(false);
      }
    } catch (error) {
      setStatus({ status: "error", type: "failed", message: "Login Failed" });
      setLoading(false);
    }
  }

  function toggleShow() {
    setShowp((oldShowp) => !oldShowp);
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  useEffect(() => {
    document.title = "Admin Login";
  }, []);

  return (
    <section className="contactform loginform">
      <div className="formwrapper">
        <div className={`wrapper`} style={{ height: "330px" }}>
          <div className="form-box login">
            <h2>Admin Login</h2>
            <form onSubmit={handleSubmit}>
              {status.message !== "" && (
                <p className="status-wrapper" id="status-wrapper">
                  <span className={`status-message ${status.type}`}>
                    {status.message}
                  </span>
                  <LiaTimesCircle
                    className="status-close"
                    onClick={() => {
                      setStatus({ status: "", type: "", message: "" });
                    }}
                  />
                </p>
              )}
              <div className="input-box">
                <span className="icon">
                  {!showp && (
                    <FaEye
                      style={{ cursor: "pointer" }}
                      className="ico"
                      onClick={toggleShow}
                    />
                  )}
                  {showp && (
                    <FaEyeSlash
                      style={{ cursor: "pointer" }}
                      className="ico"
                      onClick={toggleShow}
                    />
                  )}
                </span>
                <input
                  readOnly={loading}
                  placeholder=" "
                  name="password"
                  type={showp ? "text" : "password"}
                  value={password}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
                <label>
                  <FaLock /> Password
                </label>
              </div>
              <button type="submit" className="btn" disabled={loading}>
                {loading ? <FaSpinner className="spinner" /> : "Login"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Admin;
