import React, { useEffect, useState } from "react";
import { FaChalkboardUser, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  extractAllClasses,
  extractMatricDigits,
  showSwal,
  sortByProperty,
  updateStudentSpecial,
} from "../../AppManager";
import { nanoid } from "nanoid";
import ConfirmAction from "../../components/ConfirmAction";

export default function AdminSpecial() {
  const { pathname, state } = useLocation();
  const { student } = state;
  const { id } = useParams();
  const returnPath = pathname.slice(0, pathname.indexOf(`/${id}`));
  const navigate = useNavigate();
  const { record, classRecord, setClassRecord, currUser, maxStudents } =
    useOutletContext();
  const [formData, setFormData] = useState({ class: "" });
  const [uploading, setUploading] = useState(false);
  const [status, setStatus] = useState({ status: "", type: "", message: "" });
  const [classes, setClasses] = useState([]);
  const [close, setClose] = useState(true);

  useEffect(() => {
    if (!student || extractMatricDigits(student.matric) !== id) {
      showSwal("error", "Student not Found");
      navigate(returnPath, { replace: true });
    }
  }, [id, student, navigate, returnPath]);

  useEffect(() => {
    document.title = "Special Edit";
  }, []);

  useEffect(() => {
    setClasses(sortByProperty(extractAllClasses(record), "name"));
  }, [record]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldFormData) => ({ ...oldFormData, [name]: value }));
  }

  async function handleSubmit() {
    try {
      if (formData.class !== "") {
        setUploading(true);
        const classDetail = classes.filter(
          (classe) => classe.name === formData.class
        )[0];
        if (parseInt(classDetail.students) >= maxStudents) {
          setStatus({
            status: "full",
            type: "failed",
            message: "New Class is Full",
          });
          setUploading(false);
          return;
        }
        const updates = {
          class: formData.class,
          trainingClass: classDetail.trainingClass,
          centre: classDetail.centre,
          teacher: classDetail.teacher,
          location: classDetail.location,
        };
        const res = await updateStudentSpecial({
          ...student,
          newclass: formData.class,
          newcentre: classDetail.centre,
          newtrainingClass: classDetail.trainingClass,
        });
        if (res === "Updated") {
          for (const student in classRecord) {
            if (Object.hasOwnProperty.call(classRecord, student)) {
              let stud = classRecord[student];
              if (student === id) {
                stud = { ...stud, ...updates };
                setClassRecord((oldClassRecord) => ({
                  ...oldClassRecord,
                  [student]: stud,
                }));
              }
            }
          }
          setTimeout(() => {
            setFormData({ ...formData, name: "" });
            setStatus({
              status: "success",
              type: "success",
              message: "Updated Successfully. Redirecting...",
            });
            setTimeout(() => {
              const returnPath = pathname.slice(
                0,
                pathname.indexOf(`/special`)
              );
              navigate(returnPath, { replace: true });
            }, 2000);
          }, 1000);
        } else if (res === "Not Found") {
          setStatus({
            status: "not found",
            type: "failed",
            message: "Student not Found. Redirecting...",
          });
          setTimeout(() => {
            const returnPath = pathname.slice(0, pathname.indexOf(`/special`));
            navigate(returnPath, { replace: true });
          }, 2000);
        } else {
          setTimeout(() => {
            setStatus({
              status: "error",
              type: "failed",
              message: "Update Failed",
            });
            setUploading(false);
          }, 1000);
        }
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setUploading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  const selectClasses = classes
    .filter(
      (cent) =>
        cent.name !== student?.class &&
        cent.trainingClass !== student.trainingClass
    )
    .map((cent) => (
      <option key={nanoid()} value={cent.name}>
        {cent.name}
      </option>
    ));

  return (
    <div className="attendanceform">
      {!close && (
        <ConfirmAction
          currUser={currUser}
          setClose={setClose}
          confirmed={handleSubmit}
        />
      )}
      <div className="formwrapper">
        <div
          className="wrapper"
          style={{ height: status.message !== "" ? "380px" : "330px" }}
        >
          <div className="form-box login">
            <h2>Special Edit</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setClose(false);
              }}
            >
              {status.message !== "" && (
                <p className="status-wrapper" id="status-wrapper">
                  <span className={`status-message ${status.type}`}>
                    {status.message}
                  </span>
                  <LiaTimesCircle
                    className="status-close"
                    onClick={() => {
                      setStatus({ status: "", type: "", message: "" });
                    }}
                  />
                </p>
              )}
              <div className="input-box">
                <input
                  type="text"
                  autoComplete="off"
                  value={student.class}
                  name="name"
                  className="input"
                  readOnly
                  required
                  placeholder=" "
                />
                <label>Current Class</label>
              </div>
              <div className="input-box">
                <select
                  disabled={uploading}
                  name="class"
                  value={formData.class}
                  onChange={handleChange}
                  className="inputs"
                  required
                >
                  <option value="" disabled>
                    Choose one...
                  </option>
                  {selectClasses}
                </select>
                <label>
                  <FaChalkboardUser /> New Class
                </label>
              </div>
              <button
                type="submit"
                disabled={uploading || !student}
                className="btn"
              >
                {uploading ? <FaSpinner className="spinner" /> : "Update"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
