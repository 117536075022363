import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { LiaTimesCircle } from "react-icons/lia";
import { FaIdCard, FaCalendarDays, FaSpinner } from "react-icons/fa6";
import { getCurrTimeInfo, stripSpace } from "../../AppManager";
import { nanoid } from "nanoid";
import { findSigned } from "../admin/special/SpecialSignIn";

function parseDateString(dateString) {
  const date = dateString.split("-");
  return new Date(date.slice(1).join("-"));
}

function sortDates(datesArray) {
  // Sort the array from latest to earliest
  const array = datesArray.slice();
  return array
    .sort((a, b) => parseDateString(a) - parseDateString(b))
    .reverse();
}

export default function AttendanceSpecialSignIn() {
  const { user, classRecord } = useOutletContext();

  const [formData, setFormData] = useState({
    idnum: "",
    date: "",
    class: "",
  });
  const [loading, setLoading] = useState(false);
  const [newDate, setNewDate] = useState(false);
  const [status, setStatus] = useState({ status: "", type: "", message: "" });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Special Sign In";
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((old) => ({
      ...old,
      [name]: name === "idnum" ? stripSpace(value.toUpperCase()) : value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (
        Object.hasOwnProperty.call(classRecord, formData.idnum) ||
        Object.hasOwnProperty.call(
          classRecord,
          formData.idnum.padStart(5, "0")
        ) ||
        (formData.idnum.length === 5 &&
          formData.idnum[0] === "0" &&
          Object.hasOwnProperty.call(classRecord, formData.idnum.slice(1)))
      ) {
        const uidnum = Object.hasOwnProperty.call(classRecord, formData.idnum)
          ? formData.idnum
          : formData.idnum.length === 5 &&
            formData.idnum[0] === "0" &&
            Object.hasOwnProperty.call(classRecord, formData.idnum.slice(1))
          ? formData.idnum.slice(1)
          : formData.idnum.padStart(5, "0");
        const member = classRecord[uidnum];

        const currTimeInfo = getCurrTimeInfo();
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const nani = new Date(
          /[A-Z][a-z]/.test(formData.date)
            ? formData.date.slice(4).split("-").reverse().join("-")
            : formData.date
        );
        const date = `${days[nani.getDay()]}-${nani
          .getDate()
          .toString()
          .padStart(2, "0")}-${(nani.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${nani.getFullYear()}`;

        let time = findSigned(classRecord, formData.date);
        if (!time) {
          time = currTimeInfo.currTime24;
        }
        time = time.slice(0, 2) + currTimeInfo.currTime.slice(2);

        const status = member[date];
        if (status && status !== "ABSENT") {
          setStatus({
            status: "info",
            type: "failed",
            message: "Already signed in",
          });
          setLoading(false);
        } else {
          setStatus({
            status: "redirect",
            type: "success",
            message: "Redirecting...",
          });
          navigate(`/overview/attendance/special/confirm`, {
            state: {
              permission: true,
              idnum: uidnum,
              date,
              time,
              className: user.class,
              classRecord,
            },
          });
        }
      } else {
        setStatus({
          status: "date",
          type: "failed",
          message: "Student not Found",
        });
        setLoading(false);
      }
    } catch (error) {
      setStatus({
        status: "date",
        type: "failed",
        message: "Student not Found",
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    document.title = "Sign In";
  }, []);

  const selectDate = sortDates(classRecord.meetings).map((meet) => (
    <option key={nanoid()} value={meet}>
      {meet}
    </option>
  ));

  return (
    <div className="formwrapper">
      <div
        className="wrapper"
        style={{ height: status.message !== "" ? "400px" : "360px" }}
      >
        <div className="form-box login">
          <h2>Sign In</h2>
          <form onSubmit={handleSubmit}>
            {status.message !== "" && (
              <p className="status-wrapper" id="status-wrapper">
                <span className={`status-message ${status.type}`}>
                  {status.message}
                </span>
                <LiaTimesCircle
                  className="status-close"
                  onClick={() => {
                    setStatus({ status: "", type: "", message: "" });
                  }}
                />
              </p>
            )}
            {!newDate && (
              <div className="input-box">
                <select
                  disabled={loading}
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  className="inputs"
                  required
                >
                  <option value="" disabled>
                    Choose one...
                  </option>
                  {selectDate}
                </select>
                <label>
                  <FaCalendarDays /> Date
                </label>
                <span
                  className="download-option"
                  onClick={() => {
                    setNewDate(true);
                    setFormData((old) => ({ ...old, date: "" }));
                  }}
                >
                  New Date?
                </span>
              </div>
            )}
            {newDate && (
              <div className="input-box">
                <span className="icon">
                  <FaCalendarDays />
                </span>
                <input
                  type="date"
                  name="date"
                  className="input"
                  value={formData.date}
                  max={new Date(new Date().setDate(new Date().getDate() - 1))
                    .toISOString()
                    .slice(0, -14)}
                  min={`${new Date().getFullYear() - 1}-01-01`}
                  onChange={handleChange}
                  required
                />
                <label>Date</label>
                <span
                  className="download-option"
                  onClick={() => {
                    setNewDate(false);
                    setFormData((old) => ({ ...old, date: "" }));
                  }}
                >
                  Select Date?
                </span>
              </div>
            )}
            <div className="input-box" style={{ marginTop: "40px" }}>
              <span className="icon">
                <FaIdCard />
              </span>
              <input
                type="text"
                onChange={handleChange}
                value={formData.idnum}
                autoComplete="off"
                pattern="\d{4,5}|[0-9]{5}[A-Za-z]{2}"
                minLength={4}
                maxLength={7}
                required
                name="idnum"
                style={{ textTransform: "uppercase" }}
                placeholder=" "
              />
              <label>Matric Number</label>
            </div>
            <button type="submit" disabled={loading} className="btn">
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
