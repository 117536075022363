import React, { useEffect, useState } from "react";
import { FaSpinner, FaChalkboardUser } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { useOutletContext } from "react-router-dom";
import { setClassPassMarks } from "../../AppManager";

function PassMarksEdit() {
  const { passMarks, setPassMarks, setPreventRefetch } = useOutletContext();
  const [formData, setFormData] = useState({
    believers: 0,
    baptismal: 0,
    workers: 0,
  });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: "", type: "", message: "" });

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldFormData) => ({ ...oldFormData, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await setClassPassMarks(formData);
      if (res === "Updated") {
        setPassMarks(formData);
        setStatus({
          status: "success",
          type: "success",
          message: "Update Successful",
        });
        setLoading(false);
        setFormData("");
      } else {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setLoading(false);
      }
    } catch (error) {
      setStatus({ status: "error", type: "failed", message: "Update Failed" });
      setLoading(false);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }

    document.title = "Pass Marks";
  }, [status]);

  useEffect(() => {
    setFormData(passMarks);
  }, [passMarks]);

  useEffect(() => {
    setPreventRefetch(true);
  });

  return (
    <div className="formwrapper" style={{ margin: "50px 0" }}>
      <div className="wrapper" style={{ height: "440px" }}>
        <div className="form-box login">
          <h2>Pass Marks</h2>
          <form onSubmit={handleSubmit}>
            {status.message !== "" && (
              <p className="status-wrapper" id="status-wrapper">
                <span className={`status-message ${status.type}`}>
                  {status.message}
                </span>
                <LiaTimesCircle
                  className="status-close"
                  onClick={() => {
                    setStatus({ status: "", type: "", message: "" });
                  }}
                />
              </p>
            )}
            <div className="input-box">
              <span className="icon">
                <FaChalkboardUser />
              </span>
              <input
                readOnly={loading}
                type="number"
                onChange={handleChange}
                value={formData.believers}
                autoComplete="off"
                required
                min={1}
                max={100}
                name="believers"
                placeholder=" "
              />
              <label>Believers Class</label>
            </div>
            <div className="input-box">
              <span className="icon">
                <FaChalkboardUser />
              </span>
              <input
                readOnly={loading}
                type="number"
                onChange={handleChange}
                value={formData.baptismal}
                autoComplete="off"
                required
                min={1}
                max={100}
                name="baptismal"
                placeholder=" "
              />
              <label>Baptismal Class</label>
            </div>
            <div className="input-box">
              <span className="icon">
                <FaChalkboardUser />
              </span>
              <input
                readOnly={loading}
                type="number"
                onChange={handleChange}
                value={formData.workers}
                autoComplete="off"
                required
                min={1}
                max={100}
                name="workers"
                placeholder=" "
              />
              <label>Workers in Training</label>
            </div>
            <button type="submit" disabled={loading} className="btn">
              {loading ? <FaSpinner className="spinner" /> : "Update"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PassMarksEdit;
