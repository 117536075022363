import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Error from "./components/Error";
import NotFound from "./components/NotFound";
import ContactForm from "./pages/ContactForm";
import Student from "./pages/login/Student";
import Teacher from "./pages/login/Teacher";
import Admin from "./pages/login/Admin";
import Register from "./pages/Register";
import Overview, { loader as overviewLoader } from "./pages/Overview";
import OverviewStudent from "./pages/overview/OverviewStudent";
import OverviewClass from "./pages/overview/OverviewClass";
import TeacherDetails from "./pages/overview/TeacherDetails";
import StudentDetails from "./pages/overview/StudentDetails";
import Attendance from "./pages/overview/Attendance";
import SignIn from "./pages/attendance/SignIn";
import Statistics from "./pages/attendance/Statistics";
import EditStudent from "./pages/overview/EditStudent";
import DeleteStudent from "./pages/overview/DeleteStudent";
import Profile, { loader as profileLoader } from "./pages/Profile";
import OverviewGeneral from "./pages/admin/OverviewGeneral";
import AdminOverview, { loader as adminLoader } from "./pages/AdminOverview";
import AdminTeachers from "./pages/admin/AdminTeachers";
import AdminStudents from "./pages/admin/AdminStudents";
import AdminTeacherDetails from "./pages/admin/AdminTeacherDetails";
import AdminStudentDetails from "./pages/admin/AdminStudentDetails";
import AdminEdit from "./pages/admin/AdminEdit";
import AdminDelete from "./pages/admin/AdminDelete";
import AdminAttendance from "./pages/admin/AdminAttendance";
import AddCentre from "./pages/admin/AddCentre";
import AddClass from "./pages/admin/AddClass";
import EditClass from "./pages/admin/EditClass";
import BelieversClass from "./pages/BelieversClass";
import WorkersInTraining from "./pages/WorkersInTraining";
import BaptismalClass from "./pages/BaptismalClass";
import AdminClasses from "./pages/admin/AdminClasses";
import ConfirmSignIn from "./pages/attendance/ConfirmSignIn";
import ProfileTeacher from "./pages/ProfileTeacher";
import AdminClassDetails from "./pages/admin/AdminClassDetails";
import AllStudents from "./pages/overview/AllStudents";
import PasswordReset from "./pages/login/Reset";
import Login, { loader as loginLoader } from "./pages/login/Login";
import TeacherRegistration from "./pages/TeacherRegistration";
import AdminAllTeachers from "./pages/admin/AdminAllTeachers";
import RegisterWelcome from "./pages/RegisterWelcome";
import EditTeacher from "./pages/overview/EditTeacher";
import ChangePassword from "./pages/overview/ChangePassword";
import ProfileEdit from "./pages/ProfileEdit";
import Forbidden from "./components/Forbidden";
import FindUs from "./pages/FindUs";
import ChapelClasses from "./pages/findus/ChapelClasses";
import SapetroClasses from "./pages/findus/SapetroClasses";
import AdminReg from "./pages/AdminReg";
import MaxStudents from "./pages/admin/MaxStudents";
import PassMarksView from "./pages/admin/PassMarksView";
import PassMarksEdit from "./pages/admin/PassMarksEdit";
import EditContact, { loader as editContactLoader } from "./pages/EditContact";
import SpecialRegister from "./pages/admin/special/SpecialRegister";
import AdminSpecial from "./pages/admin/AdminSpecial";
import Specials from "./pages/admin/Specials";
import SpecialSignIn from "./pages/admin/special/SpecialSignIn";
import SpecialConfirmSignIn from "./pages/admin/special/SpecialConfirmSignIn";
import { getCurrUser } from "./Auth";
import AttendanceSpecialSignIn from "./pages/attendance/AttendanceSpecialSignIn";
import AttendanceSpecialConfirmSignIn from "./pages/attendance/AttendanceSpecialConfirmSignIn";
import EventClasses from "./pages/findus/EventClasses";
import EditWhatsapp from "./pages/admin/EditWhatsapp";

async function loader({ request }) {
  const { pathname } = new URL(request.url);
  const loginNext =
    pathname.indexOf("admin") !== -1
      ? "admin"
      : pathname.indexOf("overview") !== -1
      ? "teacher"
      : "student";
  try {
    const exclude = [
      "/",
      "/adminreg",
      "/believersclass",
      "/baptismalclass",
      "/workersintraining",
      "/contact",
      "/teacherreg",
    ];
    const contains = [
      "findus",
      "login",
      "register",
      "passwordreset",
      "profile",
    ];
    if (
      exclude.includes(pathname) ||
      contains.filter((path) => pathname.indexOf(path) !== -1).length !== 0
    ) {
      return null;
    }
    const { position } = await getCurrUser();
    if (!position || position === "none") {
      return redirect(
        `/login/${loginNext}?message=Login Required&next=${pathname}`
      );
    }
  } catch (error) {
    return redirect(`/login/${loginNext}?message=Error Occurred, Login`);
  }
  return null;
}

const transition = { duration: 0.5 };

const AnimatedRoute = ({ children }) => (
  <motion.div
    key={window.location.pathname}
    initial={{ opacity: 0, x: -100 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 100 }}
    transition={transition}
  >
    {children}
  </motion.div>
);

export default function App() {
  const routes = [
    { path: "/", element: <Home />, errorElement: <Error /> },
    { path: "adminreg", element: <AdminReg /> },
    {
      path: "believersclass",
      element: <BelieversClass />,
    },
    {
      path: "baptismalclass",
      element: <BaptismalClass />,
    },
    {
      path: "workersintraining",
      element: <WorkersInTraining />,
    },
    { path: "contact", element: <ContactForm /> },
    {
      path: "editcontact",
      element: <EditContact />,
      loader: editContactLoader,
    },
    {
      path: "findus",
      parent: "findus",
      element: <FindUs />,
    },
    {
      path: "/",
      childOf: "findus",
      element: <ChapelClasses />,
    },
    {
      path: "chapel",
      childOf: "findus",
      element: <ChapelClasses />,
    },
    {
      path: "event_center",
      childOf: "findus",
      element: <EventClasses />,
    },
    {
      path: "sapetro",
      childOf: "findus",
      element: <SapetroClasses />,
    },
    { path: "register", element: <Register /> },
    {
      path: "register/welcome",
      element: <RegisterWelcome />,
    },
    {
      path: "teacherreg",
      element: <TeacherRegistration />,
    },
    {
      path: "login",
      parent: "login",
      element: <Login />,
      loader: loginLoader,
    },
    {
      path: "/",
      childOf: "login",
      element: <Student />,
    },
    {
      path: "student",
      childOf: "login",
      element: <Student />,
    },
    {
      path: "teacher",
      childOf: "login",
      element: <Teacher />,
    },
    {
      path: "admin",
      childOf: "login",
      element: <Admin />,
    },
    {
      path: "passwordreset",
      element: <PasswordReset />,
    },
    {
      path: "passwordreset/:token",
      element: <PasswordReset />,
    },
    {
      path: "profile",
      element: <Profile />,
      loader: profileLoader,
    },
    {
      path: "profile/teacher",
      element: <ProfileTeacher />,
      loader: profileLoader,
    },
    {
      path: "profile/password",
      element: <ChangePassword />,
      loader: profileLoader,
    },
    {
      path: "profile/edit",
      element: <ProfileEdit />,
      loader: profileLoader,
    },
    {
      path: "overview",
      parent: "overview",
      element: <Overview />,
      loader: overviewLoader,
    },
    {
      path: "/",
      childOf: "overview",
      element: <OverviewClass />,
    },
    {
      path: "class",
      childOf: "overview",
      element: <OverviewClass />,
    },
    {
      path: "teacher/:id",
      childOf: "overview",
      element: <TeacherDetails />,
    },
    {
      path: "teacher/:id/edit",
      childOf: "overview",
      element: <EditTeacher />,
    },
    {
      path: "teacher/:id/password",
      childOf: "overview",
      element: <ChangePassword />,
    },
    {
      path: "students",
      childOf: "overview",
      element: <OverviewStudent />,
    },
    {
      path: "students/:id",
      childOf: "overview",
      element: <StudentDetails />,
    },
    {
      path: "students/:id/edit",
      childOf: "overview",
      element: <EditStudent />,
    },
    {
      path: "students/:id/delete",
      childOf: "overview",
      element: <DeleteStudent />,
    },
    {
      path: "allstudents",
      childOf: "overview",
      element: <AllStudents />,
    },
    {
      path: "allstudents/:id",
      childOf: "overview",
      element: <StudentDetails />,
    },
    {
      path: "attendance",
      childOf: "overview",
      parent: "attendance",
      element: <Attendance />,
    },
    {
      path: "/",
      childOf: "attendance",
      element: <SignIn />,
    },
    {
      path: "signin",
      childOf: "attendance",
      element: <SignIn />,
    },
    {
      path: "signin/confirm",
      childOf: "attendance",
      element: <ConfirmSignIn />,
    },
    {
      path: "statistics",
      childOf: "attendance",
      element: <Statistics />,
    },
    {
      path: "special",
      childOf: "attendance",
      element: <AttendanceSpecialSignIn />,
    },
    {
      path: "special/confirm",
      childOf: "attendance",
      element: <AttendanceSpecialConfirmSignIn />,
    },
    {
      path: "admin",
      parent: "admin",
      element: <AdminOverview />,
      loader: adminLoader,
    },
    {
      path: "/",
      childOf: "admin",
      element: <OverviewGeneral />,
    },
    {
      path: "studentsmax",
      childOf: "admin",
      element: <MaxStudents />,
    },
    {
      path: "overview",
      childOf: "admin",
      element: <OverviewGeneral />,
    },
    {
      path: "overview/allstudents",
      childOf: "admin",
      element: <AllStudents />,
    },
    {
      path: "overview/allstudents/:id",
      childOf: "admin",
      element: <AdminStudentDetails />,
    },
    {
      path: "overview/allstudents/:id/edit",
      childOf: "admin",
      element: <EditStudent />,
    },
    {
      path: "overview/allstudents/:id/special",
      childOf: "admin",
      element: <AdminSpecial />,
    },
    {
      path: "overview/allstudents/:id/delete",
      childOf: "admin",
      element: <DeleteStudent />,
    },
    {
      path: "overview/allteachers",
      childOf: "admin",
      element: <AdminAllTeachers />,
    },
    {
      path: "overview/allteachers/:id",
      childOf: "admin",
      element: <AdminTeacherDetails />,
    },
    {
      path: "overview/allteachers/:id/edit",
      childOf: "admin",
      element: <AdminEdit />,
    },
    {
      path: "overview/allteachers/:id/delete",
      childOf: "admin",
      element: <AdminDelete />,
    },
    {
      path: "overview/addcentre",
      childOf: "admin",
      element: <AddCentre />,
    },
    {
      path: "overview/classes",
      childOf: "admin",
      element: <AdminClasses />,
    },
    {
      path: "overview/classes/:Class",
      childOf: "admin",
      element: <AdminClassDetails />,
    },
    {
      path: "overview/classes/:Class/teacher/:id",
      childOf: "admin",
      element: <AdminTeacherDetails />,
    },
    {
      path: "overview/classes/:Class/teacher/:id/edit",
      childOf: "admin",
      element: <AdminEdit />,
    },
    {
      path: "overview/classes/:Class/teacher/:id/delete",
      childOf: "admin",
      element: <AdminDelete />,
    },
    {
      path: "overview/classes/:Class/students",
      childOf: "admin",
      element: <AdminStudents />,
    },
    {
      path: "overview/classes/:Class/students/:id",
      childOf: "admin",
      element: <AdminStudentDetails />,
    },
    {
      path: "overview/classes/:Class/students/:id/edit",
      childOf: "admin",
      element: <EditStudent />,
    },
    {
      path: "overview/classes/:Class/students/:id/special",
      childOf: "admin",
      element: <AdminSpecial />,
    },
    {
      path: "overview/classes/:Class/students/:id/delete",
      childOf: "admin",
      element: <DeleteStudent />,
    },
    {
      path: "overview/addclass",
      childOf: "admin",
      element: <AddClass />,
    },
    {
      path: "overview/editclass",
      childOf: "admin",
      element: <AdminClasses />,
    },
    {
      path: "overview/editclass/:Class",
      childOf: "admin",
      element: <EditClass />,
    },
    {
      path: "teachers",
      childOf: "admin",
      element: <AdminClasses />,
    },
    {
      path: "teachers/:Class",
      childOf: "admin",
      element: <AdminTeachers />,
    },
    {
      path: "teachers/:Class/:id",
      childOf: "admin",
      element: <AdminTeacherDetails />,
    },
    {
      path: "teachers/:Class/:id/edit",
      childOf: "admin",
      element: <AdminEdit />,
    },
    {
      path: "teachers/:Class/:id/delete",
      childOf: "admin",
      element: <AdminDelete />,
    },
    {
      path: "students",
      childOf: "admin",
      element: <AdminClasses />,
    },
    {
      path: "students/:Class",
      childOf: "admin",
      element: <AdminStudents />,
    },
    {
      path: "students/:Class/:id",
      childOf: "admin",
      element: <AdminStudentDetails />,
    },
    {
      path: "students/:Class/:id/edit",
      childOf: "admin",
      element: <EditStudent />,
    },
    {
      path: "students/:Class/:id/special",
      childOf: "admin",
      element: <AdminSpecial />,
    },
    {
      path: "students/:Class/:id/delete",
      childOf: "admin",
      element: <DeleteStudent />,
    },
    {
      path: "attendance",
      childOf: "admin",
      element: <AdminClasses />,
    },
    {
      path: "attendance/:Class",
      childOf: "admin",
      element: <AdminAttendance />,
    },
    {
      path: "special",
      parent: "special",
      childOf: "admin",
      element: <Specials />,
    },
    {
      path: "/",
      childOf: "special",
      element: <SpecialRegister />,
    },
    {
      path: "registration",
      childOf: "special",
      element: <SpecialRegister />,
    },
    {
      path: "signin",
      childOf: "special",
      element: <SpecialSignIn />,
    },
    {
      path: "signin/confirm",
      childOf: "special",
      element: <SpecialConfirmSignIn />,
    },
    {
      path: "marks",
      childOf: "admin",
      element: <PassMarksView />,
    },
    {
      path: "marks/edit",
      childOf: "admin",
      element: <PassMarksEdit />,
    },
    {
      path: "whatsapp",
      childOf: "admin",
      element: <EditWhatsapp />,
    },
    { path: "forbidden", element: <Forbidden /> },
    { path: "*", element: <NotFound /> },
  ];

  const routerElements = {
    path: "/",
    element: <Layout />,
    children: routes.map((route) =>
      route.childOf
        ? {}
        : {
            index: route.path === "/",
            path: route.path === "/" ? undefined : route.path,
            element: <AnimatedRoute>{route.element}</AnimatedRoute>,
            loader: route.loader ? route.loader : loader,
            action: route.action ? route.action : undefined,
            errorElement: route.errorElement ? route.errorElement : <Error />,
            children:
              route.path === "/"
                ? undefined
                : routes.map((rout) => {
                    if (rout.childOf === route.parent) {
                      return {
                        index: rout.path === "/",
                        path: rout.path === "/" ? undefined : rout.path,
                        element: <AnimatedRoute>{rout.element}</AnimatedRoute>,
                        loader: rout.loader ? rout.loader : loader,
                        action: rout.action ? rout.action : undefined,
                        errorElement: rout.errorElement
                          ? rout.errorElement
                          : undefined,
                        children:
                          rout.path === "/"
                            ? undefined
                            : routes.map((routs) => {
                                if (routs.childOf === rout.parent) {
                                  return {
                                    index: routs.path === "/",
                                    path:
                                      routs.path === "/"
                                        ? undefined
                                        : routs.path,
                                    element: (
                                      <AnimatedRoute>
                                        {routs.element}
                                      </AnimatedRoute>
                                    ),
                                    loader: routs.loader
                                      ? routs.loader
                                      : loader,
                                    action: routs.action
                                      ? routs.action
                                      : undefined,
                                    errorElement: routs.errorElement
                                      ? routs.errorElement
                                      : undefined,
                                  };
                                } else {
                                  return {};
                                }
                              }),
                      };
                    } else {
                      return {};
                    }
                  }),
          }
    ),
  };

  const router = createBrowserRouter([routerElements]);

  return (
    <div className="App">
      <AnimatePresence mode="wait">
        <RouterProvider router={router} />
      </AnimatePresence>
    </div>
  );
}
