import React, { useEffect, useState } from "react";
import { FaSpinner, FaWhatsapp } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { getWhatsappLink, setWhatsappLink } from "../../AppManager";

export default function EditWhatsapp() {
  const [link, setLink] = useState("");
  const [formData, setFormData] = useState({ link: "" });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });

  useEffect(() => {
    getWhatsappLink()
      .then((res) => setLink(res))
      .catch((err) => err);
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldFormData) => ({ ...oldFormData, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await setWhatsappLink(formData.link);
      if (res === "Updated") {
        setStatus({
          status: "success",
          type: "success",
          message: "Link Updated",
        });
        setLoading(false);
        setFormData({ link: "" });
      } else {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setLoading(false);
      }
    } catch (error) {
      setStatus({ status: "error", type: "failed", message: "Update Failed" });
      setLoading(false);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }

    document.title = "Edit Whatsapp Link";
  }, [status]);

  return (
    <div className="formwrapper" style={{ margin: "50px 0" }}>
      <div className="wrapper" style={{ height: "350px" }}>
        <div className="form-box login">
          <h2>Edit Whatsapp</h2>
          <form onSubmit={handleSubmit}>
            {status.message !== "" && (
              <p className="status-wrapper" id="status-wrapper">
                <span className={`status-message ${status.type}`}>
                  {status.message}
                </span>
                <LiaTimesCircle
                  className="status-close"
                  onClick={() => {
                    setStatus({ status: "", type: "", message: "" });
                  }}
                />
              </p>
            )}
            <div
              className="input-box"
              style={{ border: "none", margin: "10px 0" }}
            >
              <label
                style={{ wordBreak: "break-all", pointerEvents: "initial" }}
              >
                <strong>Current Link:</strong> {link}
              </label>
            </div>
            <div className="input-box">
              <span className="icon">
                <FaWhatsapp />
              </span>
              <input
                readOnly={loading}
                type="text"
                onChange={handleChange}
                value={formData.link}
                autoComplete="off"
                required
                min={3}
                max={20}
                name="link"
                placeholder=" "
              />
              <label>WhatsApp Group Link</label>
            </div>
            <button type="submit" disabled={loading} className="btn">
              {loading ? <FaSpinner className="spinner" /> : "Update"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
