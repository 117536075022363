import React, { useEffect, useState } from "react";
import {
  FaChalkboardUser,
  FaChurch,
  FaEnvelope,
  FaEye,
  FaEyeSlash,
  FaHandshake,
  FaIdCard,
  FaLock,
  FaSpinner,
  FaUser,
  FaUserGraduate,
} from "react-icons/fa6";
import { LiaCheckCircle, LiaTimesCircle } from "react-icons/lia";
import {
  classAssigner,
  capitalizeEachWord,
  allTrainingClasses,
  studentRegistration,
  cleanMatric,
  cleanName,
  passwordStrength,
  checkExtension,
  getCentresDirect,
  getClassDetailsDirect,
} from "../AppManager";
import { useLocation, useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import RegisterConfirmation from "./RegisterConfirmation";

function Register() {
  const { state } = useLocation();
  const [formData, setFormData] = useState({
    name: "",
    matric: "",
    email: "",
    previoustrainingclass: "",
    trainingclass: "",
    worshipcentre: "",
    image: "",
    password: "",
    confirmpassword: "",
  });
  const [status, setStatus] = useState({ status: "", type: "", message: "" });
  const [centres, setCentres] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [showp, setShowp] = useState(false);
  const [mismatch, setMismatch] = useState(false);
  const [checkProgress, setCheckProgress] = useState({
    strength: "0%",
    color: "red",
    upper: false,
    lower: false,
    special: false,
    length: false,
    number: false,
  });
  const [imgErr, setImgErr] = useState({ name: "", reason: "", status: true });
  const [uprogress, setUprogess] = useState(0);
  const [ustate, setUstate] = useState("");
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState("/user.png");
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();

  function handleChange(e) {
    const { name, type, value, checked, files } = e.target;
    setFormData((oldFormData) => {
      let newFormData = {
        ...oldFormData,
        [name]:
          type === "checkbox"
            ? checked
            : type === "file"
            ? files[0]
            : name === "name"
            ? cleanName(capitalizeEachWord(value))
            : name === "matric"
            ? cleanMatric(value)
            : value,
      };

      if (name === "password" || name === "confirmpassword") {
        setMismatch(
          newFormData.password !== newFormData.confirmpassword &&
            newFormData.confirmpassword.length > 0
            ? true
            : false
        );
        setCheckProgress(passwordStrength(newFormData.password));
      }

      if (name === "previoustrainingclass") {
        newFormData = {
          ...newFormData,
          trainingclass:
            value === "None"
              ? "Believers Class"
              : value === "Believers Class"
              ? "Baptismal Class"
              : value === "Baptismal Class"
              ? "Workers in Training"
              : "None",
        };
        setDisabled(value === "Workers in Training");
      }

      if (type === "file" && files[0]) {
        if (files[0].size <= 512000 && checkExtension(files[0].name)) {
          setImgErr({ name: "", reason: "", status: true });
          var oFReader = new FileReader();
          oFReader.readAsDataURL(files[0]);
          oFReader.onload = function (oFREvent) {
            setPreview(oFREvent.target.result);
          };
          return newFormData;
        } else {
          if (files[0].size > 512000) {
            setImgErr({ name, reason: "size", status: false });
          } else if (!checkExtension(files[0].name)) {
            setImgErr({ name, reason: "format", status: false });
          }
          return oldFormData;
        }
      } else {
        return newFormData;
      }
    });
  }

  function success(className) {
    setStatus({
      status: "success",
      type: "success",
      message: "Registration Successful. Redirecting...",
    });
    setTimeout(() => {
      setStatus({ status: "", type: "", message: "" });
      getClassDetailsDirect(className)
        .then((data) => {
          navigate("welcome", {
            state: {
              data: {
                name: formData.name,
                className,
                trainingClass: formData.trainingclass,
                teacher: data.teacher,
                location: data.location,
              },
            },
          });
        })
        .catch(() => {
          setStatus({
            status: "success",
            type: "success",
            message: "Proceed to Login",
          });
        });
    }, 3000);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setConfirm(false);
    try {
      if (!(!imgErr.status || disabled || mismatch)) {
        setUploading(true);
        const data = await classAssigner(
          formData.worshipcentre,
          formData.trainingclass
        );
        if (data) {
          if (data === "full") {
            setStatus({
              status: "full",
              type: "failed",
              message: "All Classes are Full",
            });
            setUploading(false);
          } else if (data === "Mismatch") {
            setStatus({
              status: "Mismatch",
              type: "failed",
              message: "Password Mismatched",
            });
            setUploading(false);
          } else if (data === "none") {
            setStatus({
              status: "none",
              type: "failed",
              message: "No Available Class",
            });
            setUploading(false);
          } else {
            const regData = {
              name: formData.name,
              matric: formData.matric,
              email: formData.email,
              trainingClass: formData.trainingclass,
              Class: data,
              centre: formData.worshipcentre,
              image: formData.image,
              attendance: "0%",
              password: formData.password,
              confirmpassword: formData.confirmpassword,
            };
            const res = await studentRegistration(regData);
            if (res === "Registered") {
              if (typeof formData.image === "object") {
                setUstate("Uploading...");
                const interv = setInterval(() => {
                  setUprogess((oldProgress) => {
                    if (oldProgress >= 100) {
                      clearInterval(interv);
                    }
                    const rand = Math.floor(Math.random() * 25 + 15);
                    const add = oldProgress + rand;
                    return add > 100 ? 100 : add;
                  });
                }, 500);
              }
              setTimeout(
                () => {
                  setUprogess(0);
                  success(data);
                },
                typeof formData.image === "object" ? 3000 : 1000
              );
            } else if (res === "Exists") {
              setStatus({
                status: "exists",
                type: "failed",
                message: "You are already Registered",
              });
              setUploading(false);
            } else {
              setStatus({
                status: "error",
                type: "failed",
                message: "Registration Failed",
              });
              setUploading(false);
            }
          }
        } else {
          setStatus({
            status: "error",
            type: "failed",
            message: "Registration Failed",
          });
          setUploading(false);
        }
      }
    } catch (error) {
      setStatus({
        status: "error",
        type: "failed",
        message: "Registration Failed",
      });
      setUploading(false);
    }
  }

  function toggleShow() {
    setShowp((oldShowp) => !oldShowp);
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  useEffect(() => {
    getCentresDirect()
      .then((data) => setCentres(data))
      .catch((err) => err);
  }, []);

  useEffect(() => {
    const trainingClass = state ? state.trainingClass : "";
    if (trainingClass !== "") {
      setFormData((oldFormData) => {
        return {
          ...oldFormData,
          trainingclass: trainingClass,
          previoustrainingclass:
            trainingClass === "Believers Class"
              ? "None"
              : trainingClass === "Baptismal Class"
              ? "Believers Class"
              : trainingClass === "Workers in Training"
              ? "Baptismal Class"
              : "",
        };
      });
    }
  }, [state]);

  useEffect(() => {
    document.title = "Registration";
  }, []);

  const selectCentres = centres.map((cent) => (
    <option key={nanoid()} value={cent}>
      {cent}
    </option>
  ));

  const selectTclasses = allTrainingClasses.map((tclass) => (
    <option key={nanoid()} value={tclass}>
      {tclass}
    </option>
  ));

  return (
    <section className="contactform registration">
      {(!confirm || uploading) && (
        <div className="formwrapper">
          <div
            className={`wrapper`}
            style={
              uploading
                ? { height: status.message !== "" ? "910px" : "890px" }
                : { height: status.message !== "" ? "870px" : "840px" }
            }
          >
            <div className="form-box login">
              <h2>Registration</h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setConfirm(true);
                }}
              >
                {status.message !== "" && (
                  <p className="status-wrapper" id="status-wrapper">
                    <span className={`status-message ${status.type}`}>
                      {status.message}
                    </span>
                    <LiaTimesCircle
                      className="status-close"
                      onClick={() => {
                        setStatus({ status: "", type: "", message: "" });
                      }}
                    />
                  </p>
                )}
                <div className="input-box">
                  <span className="icon">
                    <FaUser />
                  </span>
                  <input
                    readOnly={uploading}
                    placeholder=" "
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                    minLength={7}
                    maxLength={25}
                    pattern="[A-Za-z\- ]+"
                  />
                  <label>Name</label>
                </div>
                <div className="input-box">
                  <span className="icon">
                    <FaIdCard />
                  </span>
                  <input
                    readOnly={uploading}
                    placeholder=" "
                    type="text"
                    name="matric"
                    style={{ textTransform: "uppercase" }}
                    value={formData.matric}
                    onChange={handleChange}
                    autoComplete="off"
                    pattern="^(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/[A-Za-z]{3}(-[A-Za-z])?/\d{2}/\d{4,5}$|(RUN|RUn|Run|rUN|ruN|run|rUn|RUn)/(FD|fd|Fd|fD)/\d{2}/\d{4,5}$|\d{12}[A-Za-z]{2}|\d{8}[A-Za-z]{2}|[A-Za-z]{3}\d{2}/\d{2}/\d{4,5}$"
                    title="RUN/xxx/yy/yyyy | JAMB Number"
                    maxLength={18}
                    required
                  />
                  <label>Matric Number</label>
                </div>
                <div className="input-box">
                  <span className="icon">
                    <FaEnvelope />
                  </span>
                  <input
                    readOnly={uploading}
                    placeholder=" "
                    type="email"
                    name="email"
                    value={formData.email}
                    minLength={13}
                    maxLength={50}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                  <label>E-Mail</label>
                </div>
                {/* <div className="input-box">
                  <span className="icon">
                    <FaImage />
                  </span>
                  <input
                    readOnly={uploading}
                    type="file"
                    name="image"
                    onChange={handleChange}
                    accept="image/jpg, image/jpeg image/png image/heic"
                  />
                  <label>
                    A Picture of You{" "}
                    <span style={{ fontSize: "0.7em" }}>(max = 500kb)</span>
                  </label>
                  {imgErr.name === "image" &&
                    imgErr.reason === "size" &&
                    !imgErr.status && <p>Max Size Exceeded</p>}
                  {imgErr.name === "image" &&
                    imgErr.reason === "format" &&
                    !imgErr.status && <p>Invalid File Format</p>}
                </div> */}
                <div className="input-box">
                  <select
                    disabled={uploading}
                    name="previoustrainingclass"
                    value={formData.previoustrainingclass}
                    onChange={handleChange}
                    className="inputs"
                    required
                  >
                    <option value="" disabled>
                      Choose one...
                    </option>
                    <option value="None">None</option>
                    {selectTclasses}
                  </select>
                  <label>
                    <FaUserGraduate /> Previous Training Acquired
                  </label>
                </div>
                <div className="input-box">
                  <span className="icon">
                    <FaChalkboardUser />
                  </span>
                  <input
                    type="text"
                    name="trainingclass"
                    value={formData.trainingclass}
                    onChange={handleChange}
                    placeholder=" "
                    readOnly
                    disabled
                    required
                  />
                  <label>Training Class</label>
                </div>
                <div className="input-box">
                  <select
                    disabled={uploading}
                    name="worshipcentre"
                    value={formData.worshipcentre}
                    onChange={handleChange}
                    className="inputs"
                    required
                  >
                    <option value="" disabled>
                      Choose one...
                    </option>
                    {selectCentres}
                  </select>
                  <label>
                    <FaChurch /> Centre
                  </label>
                </div>
                <div
                  className="input-box"
                  style={{
                    borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`,
                  }}
                >
                  <span className="icon">
                    {!showp && (
                      <FaEye
                        style={{ cursor: "pointer" }}
                        className="ico"
                        onClick={toggleShow}
                      />
                    )}
                    {showp && (
                      <FaEyeSlash
                        style={{ cursor: "pointer" }}
                        className="ico"
                        onClick={toggleShow}
                      />
                    )}
                  </span>
                  <input
                    readOnly={uploading}
                    placeholder=" "
                    maxLength={30}
                    type={showp ? "text" : "password"}
                    name="password"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                    className="password-input"
                    value={formData.password}
                    autoComplete="off"
                    onChange={handleChange}
                    required
                  />
                  <label>
                    <FaLock /> Password
                  </label>
                </div>
                <div
                  className="input-box"
                  style={{
                    borderBottom: `2px solid ${mismatch ? "red" : "#002F63"}`,
                  }}
                >
                  <span className="icon">
                    {!showp && (
                      <FaEye
                        style={{ cursor: "pointer" }}
                        className="ico"
                        onClick={toggleShow}
                      />
                    )}
                    {showp && (
                      <FaEyeSlash
                        style={{ cursor: "pointer" }}
                        className="ico"
                        onClick={toggleShow}
                      />
                    )}
                  </span>
                  <input
                    readOnly={uploading}
                    placeholder=" "
                    maxLength={30}
                    type={showp ? "text" : "password"}
                    name="confirmpassword"
                    value={formData.confirmpassword}
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                    className="password-input"
                    autoComplete="off"
                    onChange={handleChange}
                    required
                  />
                  <label>
                    <FaHandshake /> Confirm Password
                  </label>
                </div>
                <span className="password-note">
                  <div id="strength">
                    <div
                      id="strength-bar"
                      style={{
                        width: checkProgress.strength,
                        backgroundColor: checkProgress.color,
                      }}
                    ></div>
                  </div>
                  <i>
                    At Least:
                    <ul>
                      <li className={checkProgress.upper ? "complete" : null}>
                        <span> 1 Upper Case</span>
                      </li>
                      <li className={checkProgress.lower ? "complete" : null}>
                        <span> 1 Lower Case</span>
                      </li>
                      <li className={checkProgress.number ? "complete" : null}>
                        <span> 1 Number</span>
                      </li>
                      <li className={checkProgress.special ? "complete" : null}>
                        <span> 1 Special Character</span>
                      </li>
                      <li className={checkProgress.length ? "complete" : null}>
                        <span> 6 Characters</span>
                      </li>
                    </ul>
                  </i>
                </span>
                <button
                  type="submit"
                  disabled={
                    !imgErr.status ||
                    mismatch ||
                    disabled ||
                    uploading ||
                    (checkProgress.strength !== "0%" &&
                      checkProgress.strength !== "100%")
                  }
                  className="btn"
                >
                  {uploading ? (
                    uprogress === 100 ? (
                      "Finishing..."
                    ) : (
                      <FaSpinner className="spinner" />
                    )
                  ) : (
                    "Submit"
                  )}
                </button>
                {uploading && uprogress !== 0 && (
                  <div className="upload-status">
                    <div id="upload-progress">
                      <div
                        id="upload-progress-bar"
                        className="loader-item"
                        style={{
                          width: uprogress + "%",
                          backgroundImage:
                            uprogress !== 100
                              ? "linear-gradient(110deg, #002F63 8%, #1257a7 18%, #002F63 33%)"
                              : "linear-gradient(110deg, green 8%, yellowgreen 18%, green 33%)",
                        }}
                      ></div>
                      <label>{Math.floor(uprogress)}%</label>
                    </div>
                    <div
                      style={{
                        fontSize: "0.8em",
                        fontFamily: "Georgia, 'Times New Roman', Times, serif",
                      }}
                    >
                      {uprogress === 100 ? (
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily:
                              "Georgia, 'Times New Roman', Times, serif",
                          }}
                        >
                          <LiaCheckCircle
                            style={{
                              fontSize: "1.2em",
                              marginRight: "2px",
                              color: "green",
                            }}
                          />
                          Upload Completed
                        </span>
                      ) : (
                        ustate
                      )}
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
      {confirm && !uploading && (
        <RegisterConfirmation
          formData={formData}
          handleSubmit={handleSubmit}
          preview={preview}
          edit={() => {
            setConfirm(false);
          }}
        />
      )}
    </section>
  );
}

export default Register;
