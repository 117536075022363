import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getWhatsappLink } from "../AppManager";

function RegisterWelcome() {
  const [info, setInfo] = useState({
    name: "",
    className: "",
    trainingClass: "",
    teacher: "",
    location: "",
  });
  const [link, setLink] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { name, className, trainingClass, teacher, location } =
      state && state.data
        ? state.data
        : {
            name: "",
            className: "",
            trainingClass: "",
            teacher: "",
            location: "",
          };
    if (name === "") {
      navigate("/register", { replace: true });
    } else {
      setInfo({ name, className, trainingClass, teacher, location });
    }
  }, [navigate, state]);

  useEffect(() => {
    getWhatsappLink()
      .then((res) => setLink(res))
      .catch((err) => err);

    document.title = "Welcome";
  }, []);

  return (
    <section className="contactform registration">
      <div className="formwrapper">
        <div className={`wrapper welcome`}>
          <div className="form-box login">
            <h2>Welcome</h2>
            <div>
              <p style={{ marginBottom: "5px" }}>Hello {info.name},</p>
              <p>
                Congratulations on successfully registering for the{" "}
                {info.trainingClass}!
              </p>
              <div>
                <p>
                  <strong>Class Details</strong>
                </p>
                <ul>
                  <li>
                    &nbsp;<strong>Class Name:</strong>{" "}
                    <span>{info.className}</span>
                  </li>
                  <li>
                    &nbsp;<strong>Teacher:</strong> <span>{info.teacher}</span>
                  </li>
                  <li>
                    &nbsp;<strong>Location:</strong>{" "}
                    <span>{info.location}</span>
                  </li>
                  <li>
                    &nbsp;<strong>Time:</strong>{" "}
                    <span>
                      Sundays at 6:45 am (
                      <i style={{ color: "red" }}>
                        Late coming is not allowed!
                      </i>
                      )
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                {link && link !== "Failed" && (
                  <p>
                    Click{" "}
                    <a href={link} target="_blank" rel="noreferrer">
                      here
                    </a>{" "}
                    to join Our Whatsapp Group.
                  </p>
                )}
                <p>
                  To make the most of your experience in the {info.className},
                  please ensure you come prepared with the following materials:
                </p>
                <ul className="checkmark">
                  <li>
                    <span>
                      &nbsp;{info.trainingClass} Manual{" "}
                      <i>(Available for purchase at ₦300 in your class)</i>
                    </span>
                  </li>
                  <li>
                    <span>
                      &nbsp;Bible and Writing Materials{" "}
                      <i>(Notebook and Pen)</i>
                    </span>
                  </li>
                </ul>
                <p>
                  These materials will be essential for your active
                  participation in the class discussions and activities.
                </p>
              </div>
              <p>
                To obtain your certificate upon completion of the class, the
                purchase of a form, which costs ₦1,000 is required.
              </p>
              <p>
                Once again, welcome to the {info.className}. We're excited to
                have you on this spiritual journey with us!
              </p>
              <p style={{ marginTop: "5px" }}>
                Best regards,
                <br />
                <i>RUC Training Unit</i>
              </p>
            </div>
            <Link to="/login/student">
              <button className="btn">LOG IN</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegisterWelcome;
