import React, { useEffect, useState } from "react";
import { FaAward, FaImage, FaSpinner, FaUser } from "react-icons/fa6";
import { LiaCheckCircle, LiaTimesCircle } from "react-icons/lia";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  capitalizeEachWord,
  checkExtension,
  cleanName,
  getStudent,
  showSwal,
  stripSpecialChar,
  updateStudent,
} from "../../AppManager";

function EditStudent() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const returnPath = pathname.slice(0, pathname.indexOf(`/${id}`));
  const navigate = useNavigate();
  const { record, classRecord, setClassRecord } = useOutletContext();
  const [student, setStudent] = useState({ name: "John Doe" });
  const [formData, setFormData] = useState({ name: "", image: "", score: "" });
  const [uprogress, setUprogess] = useState(0);
  const [ustate, setUstate] = useState("");
  const [uploading, setUploading] = useState(false);
  const [imgErr, setImgErr] = useState({ name: "", reason: "", status: true });
  const [status, setStatus] = useState({ status: "", type: "", message: "" });

  useEffect(() => {
    document.title = "Edit Student";
  }, []);

  useEffect(() => {
    getStudent(id, record)
      .then((data) => setStudent(data))
      .catch(() => {
        showSwal("error", "Student not Found");
        navigate(returnPath, { replace: true });
      });
  }, [id, record, navigate, returnPath]);

  function handleChange(e) {
    const { name, value, type, files } = e.target;
    setFormData((oldFormData) => {
      if (type === "file" && files[0]) {
        if (files[0].size <= 512000 && checkExtension(files[0].name)) {
          setImgErr({ name: "", reason: "", status: true });
          return { ...oldFormData, [name]: type === "file" ? files[0] : value };
        } else {
          if (files[0].size > 512000) {
            setImgErr({ name, reason: "size", status: false });
          } else if (!checkExtension(files[0].name)) {
            setImgErr({ name, reason: "format", status: false });
          }
        }
        return oldFormData;
      } else {
        return {
          ...oldFormData,
          [name]:
            type === "number" ? value : cleanName(capitalizeEachWord(value)),
        };
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (
        formData.name !== "" ||
        formData.score !== "" ||
        typeof formData.image === "object"
      ) {
        setUploading(true);
        const newname = formData.name === "" ? student.name : formData.name;
        const score =
          formData.score === "" || formData.score === 0
            ? student.score
            : formData.score;
        const downloadURL =
          typeof formData.image === "object"
            ? stripSpecialChar(student["matric"]) +
              formData["image"].name.slice(
                formData["image"].name.lastIndexOf(".")
              )
            : student["image"];
        const res = await updateStudent({
          name: newname,
          matric: student["matric"],
          image: formData["image"],
          Class: student.class,
          score,
        });
        if (res === "Updated") {
          for (const student in classRecord) {
            if (Object.hasOwnProperty.call(classRecord, student)) {
              let stud = classRecord[student];
              if (student === id) {
                stud = { ...stud, name: newname, image: downloadURL, score };
                setClassRecord((oldClassRecord) => {
                  return { ...oldClassRecord, [student]: stud };
                });
                setStudent({ ...stud });
              }
            }
          }
          if (typeof formData.image === "object") {
            setUstate("Uploading...");
            const interv = setInterval(() => {
              setUprogess((oldProgress) => {
                if (oldProgress >= 100) {
                  clearInterval(interv);
                }
                const rand = Math.floor(Math.random() * 25 + 15);
                const add = oldProgress + rand;
                return add > 100 ? 100 : add;
              });
            }, 500);
          }
          setTimeout(
            () => {
              setFormData({ ...formData, name: "" });
              setStatus({
                status: "success",
                type: "success",
                message: "Updated Successfully",
              });
              setUploading(false);
            },
            typeof formData.image === "object" ? 3000 : 1000
          );
        } else if (res === "Not Found") {
          setStatus({
            status: "not found",
            type: "failed",
            message: "Member not Found. Redirecting...",
          });
          setTimeout(() => {
            const returnPath = pathname.slice(0, pathname.indexOf(`/edit`));
            navigate(returnPath, { replace: true });
          }, 2000);
        } else {
          setTimeout(() => {
            setStatus({
              status: "error",
              type: "failed",
              message: "Update Failed",
            });
            setUploading(false);
          }, 1000);
        }
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setUploading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  return (
    <div className="attendanceform">
      <div className="formwrapper">
        <div
          className="wrapper"
          style={{
            height:
              status.message !== ""
                ? uploading !== false
                  ? "560px"
                  : "530px"
                : uploading !== false
                ? "540px"
                : "480px",
          }}
        >
          <div className="form-box login">
            <h2>Edit Info.</h2>
            <form onSubmit={handleSubmit}>
              {status.message !== "" && (
                <p className="status-wrapper" id="status-wrapper">
                  <span className={`status-message ${status.type}`}>
                    {status.message}
                  </span>
                  <LiaTimesCircle
                    className="status-close"
                    onClick={() => {
                      setStatus({ status: "", type: "", message: "" });
                    }}
                  />
                </p>
              )}
              <div className="input-box">
                <input
                  type="text"
                  autoComplete="off"
                  pattern="[A-Za-z\- ]+"
                  value={student.name}
                  name="name"
                  className="input"
                  readOnly
                  placeholder=" "
                />
                <label>Current Name</label>
              </div>
              <div className="input-box">
                <span className="icon">
                  <FaUser />
                </span>
                <input
                  readOnly={uploading}
                  type="text"
                  autoComplete="off"
                  pattern="[A-Za-z\- ]+"
                  onChange={handleChange}
                  value={formData.name}
                  name="name"
                  className="input"
                  minLength={7}
                  maxLength={25}
                  placeholder=" "
                />
                <label>New Name</label>
              </div>
              <div className="input-box">
                <span className="icon">
                  <FaAward />
                </span>
                <input
                  readOnly={uploading}
                  type="number"
                  autoComplete="off"
                  onChange={handleChange}
                  value={formData.score}
                  name="score"
                  className="input"
                  min={0}
                  max={100}
                  placeholder=" "
                />
                <label>Exam Score</label>
              </div>
              <div className="input-box">
                <span className="icon">
                  <FaImage />
                </span>
                <input
                  readOnly={uploading}
                  type="file"
                  name="image"
                  onChange={handleChange}
                  id="uuploadedfile"
                  className="input"
                  accept="image/jpg, image/jpeg image/png imge/heic"
                  style={{ marginTop: "5px" }}
                />
                <label>
                  New Image{" "}
                  <span style={{ fontSize: "0.7em" }}>(max = 500kb)</span>
                </label>
                {imgErr.name === "image" &&
                  imgErr.reason === "size" &&
                  !imgErr.status && (
                    <p style={{ marginTop: "0px" }}>Max Size Exceeded</p>
                  )}
                {imgErr.name === "image" &&
                  imgErr.reason === "format" &&
                  !imgErr.status && (
                    <p style={{ marginTop: "0px" }}>Invalid File Format</p>
                  )}
              </div>
              <button
                type="submit"
                disabled={!imgErr.status || uploading}
                className="btn"
              >
                {uploading ? (
                  uprogress === 100 ? (
                    "Finishing..."
                  ) : (
                    <FaSpinner className="spinner" />
                  )
                ) : (
                  "Update"
                )}
              </button>
              {uploading && uprogress !== 0 && (
                <div className="upload-status">
                  <div id="upload-progress">
                    <div
                      id="upload-progress-bar"
                      className="loader-item"
                      style={{
                        width: uprogress + "%",
                        backgroundImage:
                          uprogress !== 100
                            ? "linear-gradient(110deg, #002F63 8%, #1257a7 18%, #002F63 33%)"
                            : "linear-gradient(110deg, green 8%, yellowgreen 18%, green 33%)",
                      }}
                    ></div>
                    <label>{Math.floor(uprogress)}%</label>
                  </div>
                  <div
                    style={{
                      fontSize: "0.8em",
                      fontFamily: "Georgia, 'Times New Roman', Times, serif",
                    }}
                  >
                    {uprogress === 100 ? (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontFamily:
                            "Georgia, 'Times New Roman', Times, serif",
                        }}
                      >
                        <LiaCheckCircle
                          style={{
                            fontSize: "1.2em",
                            marginRight: "2px",
                            color: "green",
                          }}
                        />
                        Upload Completed
                      </span>
                    ) : (
                      ustate
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditStudent;
