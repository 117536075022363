import React, { useEffect, useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import {
  NavLink,
  Outlet,
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  getClassDetails,
  getClassPassMarks,
  getClassRecord,
  getGeneralOverview,
} from "../AppManager";
import { clearUser, getCurrUser, getTokenDetails } from "../Auth";
import Offline from "../components/Offline";

export async function loader({ request }) {
  const { pathname } = new URL(request.url);
  const currUser = await getCurrUser();
  if (
    currUser.position !== "teacher" &&
    currUser.position !== "assistant teacher"
  ) {
    return redirect(`/login/teacher?message=Login Required&next=${pathname}`);
  }
  const Record = await getGeneralOverview();
  const Class = currUser.class;
  const classDetail = getClassDetails(Class, Record);
  const ClassRecord = await getClassRecord(classDetail.trainingClass, Class);
  return { Record, ClassRecord, Class, currUser, classDetail };
}

function Overview() {
  const path = useLocation().pathname;
  const [search, setSearch] = useState("");
  const { Record, ClassRecord, currUser, classDetail } = useLoaderData();
  const [record, setRecord] = useState(Record);
  const [classRecord, setClassRecord] = useState(ClassRecord);
  const [showSearch, setShowSearch] = useState(false);
  const [off, setOff] = useState(false);
  const [passMarks, setPassMarks] = useState({
    believers: 0,
    baptismal: 0,
    workers: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    function fakeAuth() {
      const { matric, position, Class, centre, token } = getTokenDetails();
      if (position !== "teacher" && position !== "assistant teacher") {
        const check = matric || position || centre || Class || token;
        if (check) {
          clearUser();
        }
        navigate("/");
      }
    }

    window.addEventListener("storage", fakeAuth);
    return () => {
      window.removeEventListener("storage", fakeAuth);
    };
  }, [navigate]);

  useEffect(() => {
    document.title = "Overview";

    getClassPassMarks()
      .then((data) => setPassMarks(data))
      .catch((err) => err);
  }, []);

  useEffect(() => {
    const refetch = setInterval(() => {
      getGeneralOverview()
        .then(
          (data) =>
            JSON.stringify(data) !== JSON.stringify(record) && setRecord(data)
        )
        .catch((err) => err);

      getClassRecord(classDetail.trainingClass, classDetail.name)
        .then(
          (data) =>
            JSON.stringify(data) !== JSON.stringify(classRecord) &&
            setClassRecord(data)
        )
        .catch((err) => err);

      getClassPassMarks()
        .then(
          (data) =>
            JSON.stringify(data) !== JSON.stringify(passMarks) &&
            setPassMarks(data)
        )
        .catch((err) => err);
    }, 5000);

    return () => {
      clearInterval(refetch);
    };
  }, [classDetail, record, classRecord, passMarks]);

  function handleChange(e) {
    const { name, value, type } = e.target;
    if (name === "search" && type === "search") {
      setSearch(value);
    }
  }

  useEffect(() => {
    setSearch("");
    setShowSearch(false);
  }, [path]);

  const navStyles =
    path.indexOf("/overview/attendance") > -1 ? { top: "0" } : {};

  useEffect(() => {
    function changeStatus() {
      if (!navigator.onLine) {
        setOff(true);
      }
    }
    if (!navigator.onLine) {
      setOff(true);
    }
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("offline", changeStatus);
    };
  }, [navigate, path]);

  return (
    <section className="overview" style={{ position: "relative" }}>
      {off && (
        <Offline
          close={() => {
            setOff(false);
          }}
        />
      )}
      <nav style={navStyles}>
        <ul>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/overview/class"
            >
              Class
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/overview/students"
            >
              Students
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/overview/attendance"
            >
              Attendance
            </NavLink>
          </li>
        </ul>
        {showSearch && (
          <div className="student-search">
            <input
              type="search"
              name="search"
              value={search}
              placeholder="Search here..."
              maxLength={20}
              onKeyUp={handleChange}
              onChange={handleChange}
            />
            <FaMagnifyingGlass />
          </div>
        )}
      </nav>
      <Outlet
        context={{
          search,
          user: currUser,
          record,
          setRecord,
          classRecord,
          setClassRecord,
          setShowSearch,
          passMarks,
        }}
      />
    </section>
  );
}

export default Overview;
